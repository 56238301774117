<template>
  <div>
    <!-- เงินเข้าออกก่อนตัดเงิน -->
    <b-modal
      id="modal-transaction"
      title="เงินเข้า / เงินออก"
      ref="modal-transaction"
      hide-footer
    >
      <validation-observer ref="Transaction">
        <b-form class="mb-1">
          <b-row>
            <b-col xl="12">
              <div class="demo-inline-spacing mb-2">
                <b-form-radio
                  v-model="form.transaction_side"
                  name="some-radios"
                  value="in"
                  >เงินเข้า</b-form-radio
                >
                <b-form-radio
                  v-model="form.transaction_side"
                  name="some-radios"
                  value="out"
                  >เงินออก</b-form-radio
                >
              </div>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  :rules="{ required: true }"
                >
                  <b-form-input
                    v-model="form.amount"
                    placeholder="ยอดเงิน"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <b-form-radio-group
          v-model="form.payment_method"
          :options="payment_method_options"
          class="mb-1"
          value-field="item"
          text-field="name"
          disabled-field="notEnabled"
        ></b-form-radio-group>

        <b-form-group label="วันที่รับเงิน">
          <date-picker
            v-model="form.date"
            mode="datetime"
            is24hr
            :model-config="{
              type: 'string',
              mask: 'iso',
            }"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <b-form-input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="formatDateTimeForDisplay(inputValue)"
                @click="togglePopover()"
                autocomplete="off"
              />
            </template>
          </date-picker>
        </b-form-group>

        <b-form-group
          label="ช่องทางการรับเงิน"
          label-for="vue-select"
          v-if="form.payment_method == 'transfer'"
        >
          <validation-provider
            #default="{ errors }"
            name="Bank Account"
            :rules="{ required: true }"
          >
            <b-form-select
              v-model="form.account_id"
              :options="account_list_options"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="บัญชี"
          label-for="vue-select"
          v-if="form.payment_method == 'transfer999'"
        >
          <validation-provider
            #default="{ errors }"
            name="Bank Account."
            :rules="{ required: true }"
          >
            <b-form-select
              v-model="form.account_id"
              :options="account_list999_options"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
      <hr />
      <div class="text-right justify">
        <b-button
          v-if="!loading"
          variant="relief-success"
          @click="validationForm"
          size="sm"
        >
          แจ้งชำระเงิน
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormSelect,
  BButton,
  BForm,
  BFormRadio,
  BFormRadioGroup,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { required } from "@validations";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  components: {
    BFormSelect,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BFormRadio,
    BButton,
    vSelect,
    DatePicker,
  },
  props: ["info"],
  data() {
    return {
      account_list_options: [],
      account_list999_options: [],
      form: {
        amount: null,
        transaction_side: "in",
        payment_method: "transfer",
        account_id: null,
        start_date: null,
        date: Date(),
      },
      payment_method_options: [
        {
          name: "000",
          item: "transfer",
        },
        {
          name: "999",
          item: "transfer999",
        },
      ],
      loading: false,
    };
  },
  created() {
    this.loadBankAccount();
  },
  methods: {
    formatDateTimeForDisplay(isoDateTime) {
      if (isoDateTime) {
        const parts = isoDateTime.split(" ");
        const dateParts = parts[0].split("/");
        const timePart = parts[1];

        // Reformat the date string to YYYY-MM-DD
        const reformattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${timePart}`;

        const date = new Date(reformattedDate);

        // Format the date and time in 'MM/DD/YYYY HH:mm' format
        const formattedDate = date.toLocaleString("th-TH", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });

        return formattedDate;
      }
    },
    validationForm() {
      this.$refs.Transaction.validate().then((success) => {
        if (success) {
          this.AddTransaction();
        }
      });
    },
    AddTransaction() {
      this.form.main = true;
      this.form.order_id = this.$route.params.id;
      this.form.side =
        this.form.transaction_side === "in" ? "deposit" : "withdraw";
      this.form.start_date = this.info.start_date;
      this.form.end_date = this.info.end_date;
      // this.form.date = new Date();
      this.form.member_id = this.info.member_id;
      this.$http({
        method: "POST",
        url: `/payment`,
        data: [this.form],
      })
        .then(async (x) => {
          await this.$refs["modal-transaction"].hide();
          await this.$root.$emit("order-reload");
          await this.clearModal();
        })
        .catch(async (error) => {
          console.log(error);
          this.$refs["modal-transaction"].hide();
          await this.$root.$emit("order-reload");

          this.clearModal();
        });
    },
    loadBankAccount() {
      this.$http({
        method: "GET",
        url: `/bookbank/list/`,
      })
        .then((x) => {
          const data = x.data.data;
          this.account_list_options = [
            { value: null, text: "Please Select" },
            ...data.bookbank,
          ];
          this.account_list999_options = [
            { value: null, text: "Please Select" },
            ...data.bookbank999,
          ];
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    clearModal() {
      this.form = {
        amount: null,
        transaction_side: "in",
        payment_method: "transfer",
        account_id: null,
      };
    },
  },
};
</script>
