<template>
  <div>
    <b-card>
      <b-row>
        <b-col xs="3">
          <div>Order ID</div>
          <h3>{{ info.order_id }}</h3>
        </b-col>
        <b-col xs="3">
          <div>ประเภท Order</div>
          <div v-if="info.order_status_text">
            <b-badge :variant="info.order_status_text.variant">{{
              info.order_status_text.text
            }}</b-badge>
          </div>
        </b-col>
        <b-col xs="3">
          <div>การ Lock คิว</div>
          <div v-if="info.lock_status_text">
            <b-badge :variant="info.lock_status_text.variant">{{
              info.lock_status_text.text
            }}</b-badge>
          </div>
        </b-col>
        <b-col xs="3">
          <div>สถานะการรับอุปกรณ์</div>
          <div v-if="info.order_pickup_status_text">
            <b-badge :variant="info.order_pickup_status_text.variant">{{
              info.order_pickup_status_text.text
            }}</b-badge>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BListGroup,
  BListGroupItem,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BBadge,
    BListGroup,
    BListGroupItem,
  },

  props: ["info"],
  filters: {
    phone: function (phone) {
      return phone.replace(/(\d{3})(\d{3})(\d+)/, "$1-$2-$3");
    },
    day: function (date) {
      return moment(date).format("D/M");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>
