<template>
    <div>
        
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    created() {
        
    },
    mounted() {
        this.$root.$on('toast-success',async (x) => {
            await this.success(x)
        })
    },
    methods: {
        success(x){
            console.log('toast',x)
            const z = {}
            Object.assign(z,x)
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: z.title? z.title:'สำเร็จ',
                    icon: 'CheckIcon',
                    text: z.text? z.text:'ทำรายการสำเร็จแล้ว',
                    variant: 'success',
                },
            })
        }
    },
}
</script>