<template>
  <div>
    <b-card class="mt-2" no-body>
      <!-- <div class="demo-inline-spacing"> -->
      <b-row>
        <b-col xs="6">
          <div v-for="x in status_button_list" :key="x.text">
            <b-button
              @click="change_order_status(info, x.value)"
              :variant="x.variant"
              v-if="x.variant != 'danger'"
              class="mb-1"
              block
            >
              {{ x.text }}
            </b-button>
          </div>
        </b-col>
        <b-col xs="6">
          <div v-for="x in status_button_list" :key="x.text">
            <b-button
              @click="change_order_status(info, x.value)"
              :variant="x.variant"
              v-if="x.variant == 'danger'"
              class="mb-1"
              block
            >
              {{ x.text }}
            </b-button>
          </div>
          <b-button
            @click="confirmReject(info)"
            variant="danger"
            class="mb-1"
            block
          >
            ไม่เอา
          </b-button>
        </b-col>
      </b-row>

      <!-- </div> -->
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BListGroup,
  BListGroupItem,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BCard,
    BBadge,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
  },
  data() {
    return {
      status_button_list: [
        {
          value: "complete",
          text: "ลงจอง",
          lock_status: true,
          variant: "success",
        },
        {
          value: "pending-deposit",
          text: "รอยอดวางประกัน",
          lock_status: true,
          variant: "warning",
        },
        {
          value: "pending-call",
          text: "รอโทรแจ้งวางประกัน",
          lock_status: true,
          variant: "warning",
        },
        {
          value: "pending-confirm",
          text: "รอลูกค้าตอบกลับ",
          lock_status: true,
          variant: "warning",
        },
        {
          value: "pending-confirm-no-lock",
          text: "รอลูกค้าตอบกลับ (NO)",
          lock_status: false,
          variant: "danger",
        },
        {
          value: "not-response",
          text: "ติดต่อไม่ได้",
          lock_status: true,
          variant: "warning",
        },
        {
          value: "not-response-no-lock",
          text: "ติดต่อไม่ได้ (NO)",
          lock_status: false,
          variant: "danger",
        },
        {
          value: "pending-transfer",
          text: "รอโอนเงิน",
          lock_status: true,
          variant: "warning",
        },
        {
          value: "pending-transfer-no-lock",
          text: "รอโอนเงิน (NO)",
          lock_status: false,
          variant: "danger",
        },
        {
          value: "transfered",
          text: "แจ้งโอนเงิน",
          lock_status: true,
          variant: "warning",
        },
        {
          value: "pending-info-no-lock",
          text: "รอลูกค้าทำรายการ / ใบจอง",
          lock_status: false,
          variant: "danger",
        },
        {
          value: "no-show",
          text: "ไม่มารับ",
          lock_status: false,
          variant: "danger",
        },
        {
          value: "user-cancel",
          text: "ลูกค้ากดยกเลิก",
          lock_status: false,
          variant: "danger",
        },
      ],
      form: {},
    };
  },
  props: ["info"],
  methods: {
    confirmReject(info) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("ยืนยันไม่จองอุปกรณ์ !!!", {
          title: "ยืนยันไม่จองอุปกรณ์",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.change_order_status(info, "reject");
          }
        });
    },
    async change_order_status(order, new_status) {
      //   console.log("new_status", new_status);
      this.form.carts = []; //why this line
      this.$emit("loading", { loading: true });
      order.new_status = new_status;
      // order.order_id = order._id
      const temp = {
        new_status: new_status,
        order_id: order._id,
      };

      await this.$http({
        method: "POST",
        url: `/order/update-status/`,
        data: temp,
      }).then((x) => {
        if (x.data.data.ready_to_book) {
          this.$root.$emit("order-reload");
          this.$emit("loading", { loading: false });
          this.$emit("close");
        } else {
          alert("คิวไม่ว่างไม่สามารถลงจองได้");
          this.$emit("loading", { loading: false });
        }
      });

      if (new_status === "reject") {
        await this.$http({
          method: "DELETE",
          url: `/order/${order._id}`,
        }).then((x) => {
          this.$router.push({ name: "order-list" });
        });
      }
    },
  },
};
</script>
