<template>
  <div>
    <b-card no-body>
      <b-table
        :fields="product_fields"
        :items="booking_list"
        responsive="sm"
        class="mb-0"
      >
        <template #cell(product_name)="data">
          <div v-if="data.item.product_sub">
            {{ data.item.product.name }} ตัวที่

            {{ data.item.product_sub.number }} <br />
            <b-badge v-if="data.item.lock_status" variant="success"
              >ล็อคคิว</b-badge
            >
            <b-badge class="mr-1" v-if="!data.item.lock_status" variant="danger"
              >ไม่ได้ลงคิว</b-badge
            >

            <b-badge v-if="data.item.is_delete" variant="danger"
              >ถูกลบไปแล้ว</b-badge
            >
          </div>
          <div v-else>
            {{ data.item.product.name }} <br />
            <p>--- Error no product_sub ----</p>
          </div>
        </template>

        <template #cell(product_sub)="data">
          <span v-if="data.item.product_sub">
            {{ data.item.product_sub.serial }} <br />
          </span>
        </template>

        <template #cell(action)="data">
          <div v-if="!data.item.is_delete">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="relief-warning"
              class="btn-icon mr-1"
              v-b-modal.edit-booking-modal
              @click="editBooking(data.item._id)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="relief-info"
              class="btn-icon mr-1"
              v-b-modal.modal-edit-item
              @click="changeItem(data.item._id)"
            >
              เปลี่ยนตัว
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="relief-danger"
              class="btn-icon"
              @click="deleteBooking(data.item._id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>

    <add-booking-modal :info="info" :carts="booking_list" />
    <edit-item-modal :booking="change_booking" />
    <edit-booking-modal :booking="edit_booking" />
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BRow,
  BButton,
  BCol,
  BTable,
  VBModal,
} from "bootstrap-vue";
import AddBookingModal from "./AddBookingModal.vue";
import EditItemModal from "./EditItemModal.vue";
import EditBookingModal from "./EditBookingModal.vue";

import Ripple from "vue-ripple-directive";
import * as axios from "axios";

export default {
  components: {
    BBadge,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    VBModal,
    AddBookingModal,
    EditItemModal,
    EditBookingModal,
  },
  props: ["info"],

  mounted() {
    /// เปลี่ยนมาใช้อันนี้ให้หมด
    this.$root.$on("order-reload", async () => {
      await this.load_data();
    });
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      order_id: null,
      product_fields: [
        { key: "product_name", label: "รายการ" },
        { key: "product_sub.serial", label: "Serial" },
        { key: "price", label: "ค่าเช่า" },
        "Action",
      ],
      booking_list: [],
      change_booking: null,
      edit_booking: null,
    };
  },
  created() {
    this.tab_select(0);
    this.order_id = this.info.order_id;
    this.load_data();
    this.$root.$on("click", () => {
      this.load_data();
    });
  },
  methods: {
    editBooking(booking_id) {
      this.edit_booking = this.booking_list.find((x) => x._id == booking_id);
    },
    changeItem(booking_id) {
      this.change_booking = this.booking_list.find((x) => x._id == booking_id);
    },
    deleteBooking(booking_id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete everything.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$http({
              method: "DELETE",
              url: `/order/booking/${booking_id}`,
            }).then((x) => {
              //    this.load_data()
              this.$root.$emit("order-reload");
            });
          }
        });
    },
    tab_select(tab_id) {
      this.button_variant = [...Array(5)].fill("aaa");
      this.button_variant[tab_id] = "primary";
      this.selected_tab = tab_id;
    },
    load_data() {
      this.$http({
        method: "POST",
        url: `/order/booking/list`,
        data: { order_id: this.$route.params.id },
      }).then((x) => {
        this.booking_list = x.data.data;
        this.$emit("booking", x.data.data);
      });
    },
  },
};
</script>
