<template>
  <div>
    <b-card>
      <b-table
        :fields="product_fields"
        :items="info.tax_invoices"
        responsive="sm"
        class="mb-0"
      >
        <template #cell(invoice_no)="data">
          <span> {{ data.item.tax_invoice_no }} <br /> </span>
        </template>

        <template #cell(detail)="data">
          <li
            v-for="(suggestion, index) in data.item.items"
            :key="index"
            class="suggestion-group-suggestion cursor-pointer"
          >
            <span> {{ suggestion.item_name }} </span>
          </li>
        </template>

        <template #cell(tax_invoice_price)="data">
          <span> {{ data.item.price_after_vat | number }} <br /> </span>
        </template>

        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
            v-if="!data.item.cancel_status"
          >
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
              >
                รายละเอียด <feather-icon icon="ChevronDownIcon" />
              </b-button>
            </template>

            <b-dropdown-item @click="navigateToPrint(data.item._id)">
              <feather-icon icon="PrinterIcon" size="16" />
              <span class="align-middle ml-50">Print</span>
            </b-dropdown-item>

            <b-dropdown-item @click="navigateToEdit(data.item._id)">
              <feather-icon icon="EditIcon" size="16" />
              <span class="align-middle ml-50">แก้ไข</span>
            </b-dropdown-item>
            <b-dropdown-item @click="cancel_tax_invoice(data.item._id)">
              <feather-icon icon="XIcon" size="16" />
              <span class="align-middle ml-50">ยกเลิกใบกำกับภาษี</span>
            </b-dropdown-item>
            <hr />
            <b-dropdown-item
              :disabled="!deletAble(data.item)"
              @click="delete_tax_invoice(data.item._id)"
            >
              <feather-icon icon="Trash2Icon" size="16" />
              <span class="align-middle ml-50">ลบ</span>
            </b-dropdown-item>
          </b-dropdown>
          <div v-else>
            <b-dropdown variant="link" no-caret toggle-class="p-0" right>
              <template #button-content>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="btn-icon"
                >
                  ใบกำกับภาษีถูกยกเลิก <feather-icon icon="ChevronDownIcon" />
                </b-button>
              </template>
              <b-dropdown-item
                :disabled="!deletAble(data.item)"
                @click="delete_tax_invoice(data.item._id)"
              >
                <feather-icon icon="Trash2Icon" size="16" />
                <span class="align-middle ml-50">ลบ</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { getUserRole } from "@/auth/utils";
import Ripple from "vue-ripple-directive";

export default {
  props: ["info"],
  components: {
    BCard,
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ROLE: null,
      product_fields: [
        { key: "invoice_no", label: "เลขที่ใบกำกับภาษี" },
        { key: "detail", label: "ข้อมูลใบกำกับ" },
        { key: "tax_invoice_price", label: "ยอดใบกำกับภาษี" },
        { key: "action", label: "Action" },
      ],
    };
  },
  created() {
    this.ROLE = getUserRole();
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
  methods: {
    deletAble(x) {
      const today = new Date();
      const createdDate = new Date(x.createdAt);

      if (this.ROLE === "owner") {
        return true;
      } else {
        if (
          today.getDate() === createdDate.getDate() &&
          today.getMonth() === createdDate.getMonth() &&
          today.getFullYear() === createdDate.getFullYear()
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    navigateToEdit(invoice_id) {
      window.open("/tax-invoice/edit/" + invoice_id, "_blank");
    },
    navigateToPrint(invoice_id) {
      window.open("/tax-invoice/print/" + invoice_id, "_blank");
    },

    cancel_tax_invoice(id) {
      this.$bvModal
        .msgBoxConfirm("ยืนยันการยกเลิก !!!", {
          title: "ยืนยันการยกเลิก?",
          // size: 'sm',
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$http({
              method: "POST",
              url: `/tax-invoice/cancel`,
              data: { invoice_id: id },
            }).then((x) => {
              this.$root.$emit("order-reload");
            });
          }
        });
    },
    delete_tax_invoice(id) {
      this.$bvModal
        .msgBoxConfirm("ยืนยัน ลบ เอกสาร !!!", {
          title: "ยืนยัน ลบ เอกสาร?",
          // size: 'sm',
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$http({
              method: "POST",
              url: `/tax-invoice/delete`,
              data: { invoice_id: id },
            }).then((x) => {
              this.$root.$emit("order-reload");
            });
          }
        });
    },
  },
};
</script>
