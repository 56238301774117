<template>
  <div>
    <b-modal
      id="edit-booking-modal"
      title="แก้ไขข้อมูลอุปกรณ์"
      ref="edit-booking-modal"
      no-close-on-backdrop
      hide-footer
    >
      <b-form-group label="ค่าเช่า">
        <b-form-input v-model="form.price" />
      </b-form-group>
      <b-form-group label="วิธีรับอุปกรณ์">
        <b-form-radio-group v-model="form.lock_status">
          <b-form-radio :value="true"> ล็อคคิว </b-form-radio>
          <b-form-radio :value="false"> ไม่ได้ลงคิว </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-button @click="postEdit" variant="success">แก้ไข Booking</b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BAlert,
} from "bootstrap-vue";
import _ from "lodash";

export default {
  components: {
    BButton,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
  },
  props: ["booking"],
  data() {
    return {};
  },
  computed: {
    form() {
      return Object.assign({}, this.booking);
    },
  },
  methods: {
    postEdit() {
      this.$http({
        method: "PUT",
        url: "/order/edit-booking",
        data: this.form,
      }).then((x) => {
        this.$refs["edit-booking-modal"].hide();
        this.$root.$emit("order-reload");
      });
    },
  },
};
</script>
