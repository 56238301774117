<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>ประวัติการชำระเงิน</h4>
      <div class="mb-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-add-payment
          variant="relief-warning"
          size="sm"
        >
          ตัดยอด
        </b-button>
      </div>
    </div>
    <b-card no-body v-if="payment_items.length > 0">
      <b-table
        :fields="fields"
        :items="payment_items"
        responsive="sm"
        class="mb-0"
      >
        <template #cell(create_at)="data">
          <span class="text-nowrap">{{ data.item.date | datetime }}</span>
          <br />
          {{ data.item._id }} <br />
          {{ data.item.start_date | date_short }} ||
          {{ data.item.end_date | date_short }}
        </template>

        <template #cell(amount_deposit)="data">
          <span class="text-nowrap" v-if="data.item.side === 'deposit'">{{
            comma(data.item.amount)
          }}</span>
        </template>

        <template #cell(amount_withdraw)="data">
          <span class="text-nowrap" v-if="data.item.side === 'withdraw'">{{
            comma(data.item.amount)
          }}</span>
        </template>

        <template #cell(bookbanks)="data">
          <div v-if="data.item.account_id">
            <span>{{ data.item.bookbanks.display_name }}</span> <br />
            <br />
            <div v-if="data.item.refund_detail">
              โอนคืน <br />
              {{ data.item.refund_detail.account_number }}
              {{ data.item.refund_detail.bank_name }}
            </div>

            <!-- {{data.item.bookbanks.bank_name}} <br>
                    {{data.item.bookbanks.account_number }} <br>
                    {{data.item.bookbanks.account_name}}  -->
          </div>
        </template>

        <!-- note -->
        <template #cell(payment_type)="data">
          <!-- <b-badge :variant="mapText(data.item.payment_type).variant">{{ mapText(data.item.payment_type).text }}</b-badge> -->
          {{ mapText(data.item.payment_type).text }} <br />
          <div v-if="data.item.note">Note: {{ data.item.note }}</div>
        </template>

        <!-- payment_method -->
        <template #cell(payment_method)="data">
          {{ mapText(data.item.payment_method).text }}
          <!-- <b-badge :variant="mapText(data.item.payment_method).variant">{{ mapText(data.item.payment_method).text }}</b-badge> -->
        </template>

        <!-- action -->
        <template #cell(action)="data">
          <div class="demo-inline-spacing">
            <b-button
              size="sm"
              variant="relief-info"
              @click="copy_info(data.item)"
              v-if="data.item.side === 'withdraw'"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>

            <b-button
              size="sm"
              variant="relief-warning"
              @click="edit_selected(data.item)"
              v-b-modal.edit-payment-modal
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="relief-danger"
              class="btn-icon"
              @click="confirmDelete(data.item._id)"
              v-if="show_delete(data.item.createdAt)"
              size="sm"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
            <b-button
              size="sm"
              v-if="data.item.side !== 'withdraw'"
              variant="relief-secondary"
              class=""
              v-b-modal.withdraw-modal
              @click="return_selected(data.item)"
            >
              คืนเงิน
            </b-button>
            <!-- <b-button
              size="sm"
              v-if="
                data.item.side !== 'withdraw' &&
                data.item.payment_type == 'deposit'
              "
              variant="relief-warning"
              class=""
              v-b-modal.withdraw-modal
              @click="return_selected(data.item)"
            >
              ตัดเงินประกัน
            </b-button> -->
          </div>
        </template>

        <!-- side -->
        <template #cell(side)="data">
          <b-badge :variant="mapText_side(data.item.side).variant">
            {{ mapText_side(data.item.side).text }}
          </b-badge>
          <br />
          <b-badge v-if="data.item.pending_refund === true">
            <a
              :href="'/account/pending-refund'"
              target="_blank"
              style="color: inherit; text-decoration: none"
            >
              รอโอนเงินคืน
            </a>
          </b-badge>

          <!-- http://localhost:8080/account/pending-refund -->
          <div v-if="data.item.refund_detail">
            <b-badge
              v-if="data.item.refund_detail.refund_status === 'refund_complete'"
              variant="success"
              >คืนเงินแล้ว
            </b-badge>
          </div>
        </template>
      </b-table>
    </b-card>
    <div v-else>
      <b-alert variant="warning" show>
        <div class="alert-body">
          <span>ยังไม่มีรายการชำระเงิน</span>
        </div>
      </b-alert>
    </div>
    <withdraw-modal :data="selected"></withdraw-modal>
    <edit-payment-modal :info="editselected"></edit-payment-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BButton,
  BCol,
  BTable,
  BAlert,
  BBadge,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import WithdrawModal from "./WithdrawModal.vue";
import EditPaymentModal from "./EditPaymentModal.vue";
import Ripple from "vue-ripple-directive";
import { getUserRole } from "@/auth/utils";

import moment from "moment";

import _ from "lodash";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BAlert,
    BBadge,
    WithdrawModal,
    EditPaymentModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      payment_log: {},
      fields: [
        { key: "create_at", label: "วัน-เวลา" },
        { key: "amount_deposit", label: "เงินเข้า" },
        { key: "amount_withdraw", label: "เงินออก" },
        { key: "payment_type", label: "ประเภทรายรับ" },
        { key: "side", label: "เข้า / ออก" },
        { key: "bookbanks", label: "บัญชี" },
        // { key: 'payment_method', label: 'วิธีชำระเงิน' },
        "action",
      ],
      payment_items: [],
      selected: {},
      editselected: {},
      ROLE: null,
    };
  },
  mounted() {
    /// เปลี่ยนมาใช้อันนี้ให้หมด
    this.$root.$on("order-reload", async () => {
      await this.load_payment_history();
    });
  },
  created() {
    this.tab_select(0);
    this.load_payment_history();
    this.ROLE = getUserRole();
  },

  computed: {
    mapText() {
      const statusColor = {
        shipping: { text: "ค่าส่ง", variant: "warning" },
        deposit: { text: "เงินประกัน", variant: "success" },
        damage: { text: "ค่าอุปกรณ์เสียหาย", variant: "danger" },
        rental: { text: "ค่าเช่า", variant: "info" },
        cash: { text: "เงินสด", variant: "success" },
        cash999: { text: "เงินสด.", variant: "success" },
        transfer: { text: "โอน", variant: "info" },
        transfer999: { text: "โอน.", variant: "info" },
        creditcard: { text: "รูดบัตรเครดิต", variant: "warning" },
        card_verify: { text: "กันวงเงิน", variant: "secondary" },
        sale: { text: "ขายอุปกรณ์", variant: "info" },
      };
      return (status) => {
        if (statusColor[status]) {
          return statusColor[status];
        } else {
          return { text: "error", variant: "danger" };
        }
      };
    },
    mapText_side() {
      const statusColor = {
        deposit: { text: "เข้า", variant: "success" },
        withdraw: { text: "ออก", variant: "warning" },
      };
      return (status) => {
        if (statusColor[status]) {
          return statusColor[status];
        } else {
          return { text: "error", variant: "danger" };
        }
      };
    },
  },
  methods: {
    async copy_info(info) {
      const member = await this.$http({
        method: "GET",
        url: `/member/view/${info.member_id}`,
      }).then((z) => {
        return z.data.data;
      });
      console.log(info);

      const login = member.gen_username || member.retro_username;
      const start_date = moment(info.start_date).format("D/M/YYYY");
      const amount = this.comma(info.amount);

      const copyText = `โอนคืน ${login}
${member.first_name} ${member.last_name}
รับ ${start_date}
------------------
ยอดโอน ${amount}
------------------
เงินอยู่ ${info.bookbanks.t_bank_code}`;

      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(copyText)
          .then(() => {
            this.toast_success("Copy แล้ว");
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
          });
      } else {
        console.warn("Clipboard API not supported");
      }

      console.log("copyText", copyText);

      // โอนคืน nipan
      // นิพันธ์ โอฬารนิเวศน์
      // รับ 23/7/2024
      // ยอดโอน 3,000
      // เงินอยู่ ไทยพานิชย์
    },
    show_delete(dateToCheck) {
      if (this.ROLE === "owner") {
        return true;
      } else {
        const today = new Date();
        const date_to_check = new Date(dateToCheck);

        const isSameDay =
          today.getFullYear() === date_to_check.getFullYear() &&
          today.getMonth() === date_to_check.getMonth() &&
          today.getDate() === date_to_check.getDate();
        return isSameDay;
      }
    },
    edit_selected(row) {
      this.editselected = row;
    },
    return_selected(row) {
      this.selected = row;
    },
    comma(x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    tab_select(tab_id) {
      this.button_variant = [...Array(5)].fill("aaa");
      this.button_variant[tab_id] = "primary";
      this.selected_tab = tab_id;
    },
    async load_payment_history() {
      this.$http({
        method: "POST",
        url: `/payment/order/`,
        data: {
          order_id: this.$route.params.id,
          main: false,
        },
      }).then((x) => {
        const data = x.data.data;
        this.payment_items = data.table;
      });
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "ลบรายการชำระเงิน",
          icon,
          text: "ลบรายการชำระเงินแล้ว",
          variant,
        },
      });
    },
    confirmDelete(payment_id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("ยืนยีนลบรายการชำระเงิน", {
          title: "ลบการชำระเงิน",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$http({
              method: "DELETE",
              url: `/payment/${payment_id}`,
            }).then(async (x) => {
              await this.load_payment_history();
              this.showToast("success", "CheckIcon");
              await this.$root.$emit("order-reload");
            });
          }
        });
    },
  },
  filters: {
    datetime: function (date) {
      return moment(date).format("D/M/Y - HH:mm");
    },
    chatdate: function (date) {
      return moment(date).format("D/M/Y");
    },
    account_no: function (phone) {
      return phone.replace(/(\d{3})(\d{1})(\d{5})(\d+)/, "$1-$2-$3-$4");
    },
    date_short: function (date) {
      if (date) {
        return moment(date).format("D-MM");
      }
    },
  },
};
</script>
