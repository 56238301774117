<template>
  <div>
    <b-modal
      id="extend-rental-modal"
      title="เช่าต่อ"
      ref="extend-rental-modal"
      no-close-on-backdrop
      hide-footer
    >
      <b-overlay
        :show="overlay"
        rounded="sm"
        class="dark-layout"
        variant="dark"
      >
        <h5>วันที่รับคืนเดิม</h5>
        <b-card no-body>
          <b-list-group flush>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>เวลาคืนเดิม</b>
                <div>
                  {{ info.end_date | day }} |
                  <b-badge variant="success">{{
                    info.end_date | time
                  }}</b-badge>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <hr />
        <h5>เช่าต่อถึงวันที่</h5>
        <date-picker
          v-model="temp.new_end_date"
          :masks="{ input: 'D/M/YYYY' }"
          :model-config="modelConfig"
          mode="date"
          @input="passEvent"
          :min-date="info.end_date"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <b-form-group>
              <!-- :mode="date-time" -->
              <b-form-input
                class="bg-white border px-2 py-1 rounded"
                :value="inputValue"
                @click="togglePopover()"
                autocomplete="off"
              />
            </b-form-group>
          </template>
        </date-picker>
        <!-- เวลา -->
        <b-form-radio-group
          class="mb-1"
          @change="passEvent"
          v-model="temp.new_end_time"
        >
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="temp.new_end_time"
              name="some-radio-2"
              value="13:00:00"
            >
              ก่อน 13:00
            </b-form-radio>
            <b-form-radio
              v-model="temp.new_end_time"
              name="some-radio-2"
              value="14:00:00"
            >
              ก่อน 14:00
            </b-form-radio>
            <b-form-radio
              v-model="temp.new_end_time"
              name="some-radio-2"
              value="16:00:00"
            >
              ก่อน 16:00
            </b-form-radio>
            <b-form-radio
              v-model="temp.new_end_time"
              name="some-radio-2"
              value="19:00:00"
            >
              ก่อน 19:00
            </b-form-radio>
          </div>
        </b-form-radio-group>

        ค่าเช่าต่อ
        <b-badge v-if="info.is_extend" variant="danger"
          >รายการนี้เช่าต่อ</b-badge
        >
        <b-form-radio-group
          class="mb-1"
          @change="passEvent"
          v-model="temp.extend_type"
        >
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="temp.extend_type"
              name="some-radio-x2"
              value="extend_promotion"
            >
              เช่าต่อรอบแรก
            </b-form-radio>
            <b-form-radio
              v-model="temp.extend_type"
              name="some-radio-x2"
              value="extend_no_promotion"
            >
              เช่าต่อรอบ 2
            </b-form-radio>
          </div>
        </b-form-radio-group>

        <div v-if="checked">
          <hr />
          <div class="d-flex justify-content-between">
            <b>ระยะเวลา</b>
            <div>{{ res_info.info.extend_durations }}</div>
          </div>

          <hr />
          <div class="d-flex justify-content-between">
            <b>ค่าเช่าต่อ</b>
            <div>{{ res_info.info.extend_price | number }}</div>
          </div>

          <hr />
          <div class="d-flex justify-content-between">
            <b>คิดค่าเช่า</b>
            <div>
              <b-input-group append="฿" class="mb-1">
                <b-form-input
                  class="text-right"
                  v-model="res_info.info.extend_price"
                />
              </b-input-group>
            </div>
          </div>

          <div class="mt-1 mb-1">
            <b-alert
              show
              variant="success"
              class="mb-2"
              v-if="res_info.info.ready_to_book"
            >
              <div class="alert-body mb-2">
                <feather-icon icon="InfoIcon" class="mr-50" />
                คิวว่าง
              </div>
            </b-alert>

            <b-alert show variant="danger" v-else class="mb-2">
              <div class="alert-body">
                <feather-icon icon="InfoIcon" class="mr-50" />
                คิวไม่ว่าง !!!!
              </div>
            </b-alert>
          </div>
        </div>
        <br />
        <hr />

        <div class="d-flex justify-content-between">
          <b-button
            class="mb-1 text-right"
            variant="relief-warning"
            @click="passEvent"
          >
            เช็ค
          </b-button>

          <b-button
            class="mb-1 text-right"
            variant="relief-success"
            @click="createExtend"
          >
            ทำเช่าต่อ
          </b-button>
        </div>

        <!-- <b-button
          v-if="showCheck"
          class="mb-1"
          variant="relief-info"
          @click="check"
        >
          Check
        </b-button> -->
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import {
  BOverlay,
  BBadge,
  BCard,
  BButton,
  BFormRadioGroup,
  BFormRadio,
  BCol,
  BRow,
  BInputGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BAlert,
  BListGroup,
} from "bootstrap-vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";

export default {
  components: {
    BOverlay,
    BButton,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BAlert,
    DatePicker,
    BBadge,
    BCard,
    BListGroup,
    BListGroupItem,
    BCol,
    BFormRadioGroup,
    BFormRadio,
    BRow,
  },
  props: ["info"],
  computed: {
    showCheck() {
      if (this.temp.new_end_date) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      temp: {
        new_end_date: new Date().toISOString().slice(0, 10),
        new_end_time: "13:00:00",
        extend_type: "extend_promotion",
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      rental_info: Object.assign({}, this.info),
      form: {
        info: {},
        carts: [],
      },
      overlay: false,
      response_section: false,
      res_info: {
        info: {
          ready_to_book: false,
        },
      },
      checked: false,
    };
  },
  created() {
    this.rental_info.start_time = moment(this.info.start_date).format(
      "HH:mm:00"
    );
    this.rental_info.end_time = moment(this.info.end_date).format("HH:mm:00");
    this.rental_info.start_date = moment(this.info.start_date).format(
      "YYYY-MM-DD"
    );
    this.rental_info.end_date = moment(this.info.end_date).format("YYYY-MM-DD");

    if (this.info.is_extend) {
      this.temp.extend_type = "extend_no_promotion";
    }
  },

  methods: {
    passEvent() {
      this.overlay = true;
      let temp = {};
      temp.end_date = moment.tz(
        this.form.end_date + " " + this.form.end_time,
        "Asia/Bangkok"
      );
      this.form.info.end_date = temp.end_date;
      this.check();
    },
    check() {
      this.form.carts = this.$parent.booking;
      this.form.info = {
        start_date: moment.tz(
          this.rental_info.start_date + " " + this.rental_info.start_time,
          "Asia/Bangkok"
        ),
        end_date: moment.tz(
          this.rental_info.end_date + " " + this.rental_info.end_time,
          "Asia/Bangkok"
        ),
        new_end_date: moment.tz(
          this.temp.new_end_date + " " + this.temp.new_end_time,
          "Asia/Bangkok"
        ),
      };

      this.form.carts = this.form.carts.map((cart) => ({
        ...cart,
        start_date: this.form.info.end_date,
        end_date: this.form.info.new_end_date,
      }));

      this.form.info.extend_type = this.temp.extend_type;

      this.$http({
        method: "POST",
        url: "/queue/check-price-extend",
        data: this.form,
      })
        .then((x) => {
          this.res_info = x.data.data;
          this.form.carts = this.res_info.carts;
          this.checked = true;
          this.overlay = false;
          console.log("res_info", this.res_info);
        })
        .catch((e) => {
          this.overlay = false;
        });
    },
    createExtend() {
      // this.$router.push("/order/view/" + "656700ef683c11c842413602");
      this.form.info.member_id = this.info.member_id;
      this.form.info.extend_durations = this.res_info.info.extend_durations;
      this.form.info.extend_price = this.res_info.info.extend_price;
      this.form.info.deposit_price = this.info.deposit_price;
      this.form.info.pickup_method = this.info.pickup_method;
      this.form.info.full_order_id = this.info._id;
      this.form.info.new_start_date = this.form.info.end_date;

      this.$http({
        method: "POST",
        url: "/order/extend",
        data: this.form,
      })
        .then((x) => {
          const order_id = x.data.data._id;
          this.overlay = false;
          this.$refs["extend-rental-modal"].hide();
          this.$router.push({
            name: "order-view",
            params: { id: order_id },
          });
          window.location.reload();
        })
        .catch((e) => {
          this.overlay = false;
        });
    },
  },
  filters: {
    day: function (date) {
      return moment(date).format("D-M-YYYY");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>
