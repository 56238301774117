<template>
  <div>
    <b-modal
      id="edit-transaction-modal"
      title="แก้ไขข้อมูล Transaction"
      ref="edit-transaction-modal"
      no-close-on-backdrop
      hide-footer
    >
      <b-overlay
        :show="overlay"
        rounded="sm"
        class="dark-layout"
        variant="dark"
      >
        <!-- <h5></h5> -->
        <b-card no-body>
          <h4>{{ info._id }}</h4>
          <b-list-group flush>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>วันที่รับเงินเดิม</b>
                <div>
                  {{ info.date | day }} |
                  <b-badge variant="success">{{ info.date | time }}</b-badge>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
          <hr />
          <b-form-group label="วันที่รับเงิน">
            <date-picker
              v-model="edit_form.date"
              mode="datetime"
              is24hr
              :model-config="{
                type: 'string',
                mask: 'iso',
              }"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <b-form-input
                  class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="formatDateTimeForDisplay(inputValue)"
                  @click="togglePopover()"
                  autocomplete="off"
                />
              </template>
            </date-picker>
          </b-form-group>
          <br />
          <b-button variant="success" @click="postChange">
            เปลี่ยนวันที่
          </b-button>
        </b-card>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BOverlay,
  BBadge,
  BRow,
  BCard,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormInput,
  BButton,
  BFormTextarea,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import DateSelector from "./DateSelector.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

import Ripple from "vue-ripple-directive";
import moment from "moment";
import axios from "axios";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCol,
    BRow,
    BCard,
    BBadge,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BOverlay,
    DateSelector,
    DatePicker,
  },
  props: ["info"],
  data() {
    return {
      overlay: false,
      payment_info: Object.assign({}, this.info),
      response_section: false,
      form: {},
    };
  },
  computed: {
    edit_form() {
      return Object.assign({}, this.info);
    },
  },
  created() {},
  methods: {
    formatDateTimeForDisplay(isoDateTime) {
      const parts = isoDateTime.split(" ");
      const dateParts = parts[0].split("/");
      const timePart = parts[1];
      const reformattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${timePart}`;

      const date = new Date(reformattedDate);
      // Format the date and time in 'MM/DD/YYYY HH:mm' format
      const formattedDate = date.toLocaleString("th-TH", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      return formattedDate;
    },
    // Add other methods as necessary
    toast(title, body, variant) {
      this.$bvToast.toast(body, {
        title: `${title}`,
        toaster: "b-toaster-top-right",
        variant: variant,
        solid: true,
        appendToast: false,
      });
    },
    postChange() {
      const change_obj = {};
      change_obj.date = this.edit_form.date;
      change_obj._id = this.edit_form._id;
      this.$http({
        method: "POST",
        url: `/payment/edit`,
        data: change_obj,
      }).then((x) => {
        this.$refs["edit-transaction-modal"].hide();
        this.toast("เปลี่ยนวันที่", "เปลี่ยนวันท่ีเรียบร้อยแล้ว", "success");
        this.$root.$emit("order-reload");
      });
    },
  },
  filters: {
    day: function (date) {
      return moment(date).format("D-M-YYYY");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>
