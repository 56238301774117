<template>
  <div>
    <b-modal
      id="modal-edit-card-deposit"
      title="เช็คสถานะ"
      ref="modal-edit-card-deposit"
      no-close-on-backdrop
      hide-footer
    >
      <b-alert show class="mt-1" v-if="loading">
        <div class="alert-body">
          <h3><b-spinner /> &nbsp;Loading</h3>
        </div>
      </b-alert>
      <div v-else>
        <div v-if="info.deposit_charge">
          <h4>เงินประกัน</h4>

          <b-list-group flush>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>Token</b>
                {{ info.deposit_charge.id }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>ยอดวางประกัน</b>
                {{ (info.deposit_charge.amount / 100) | number }} บาท
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>ชื่อหน้าบัตร</b>
                {{ info.deposit_charge.card.name }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>วันหมดอายุบัตร</b>
                {{ info.deposit_charge.card.expiration_month }} /
                {{ info.deposit_charge.card.expiration_year }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>เลขท้ายบัตร</b>
                {{ info.deposit_charge.card.last_digits }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>security_code_check</b>
                {{ info.deposit_charge.card.security_code_check }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>created_at</b>
                {{ info.deposit_charge.created_at | datetime }}
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>วงเงินหมดอายุ</b>
                {{ info.deposit_charge.expires_at | datetime }}
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>สถานะ</b>
                <div>
                  {{ info.deposit_charge.status }}<br />
                  <b-badge
                    :variant="depositSuccess(info.deposit_charge).variant"
                    v-if="depositSuccess(info.deposit_charge).show"
                  >
                    {{ depositSuccess(info.deposit_charge).message }}
                  </b-badge>
                </div>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>วันที่คืนวงเงิน</b>
                {{ info.deposit_charge.reversed_at | datetime }}
              </div>
            </b-list-group-item>

            <!-- <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>status</b>
                {{ info.deposit_charge.status }}
              </div>
            </b-list-group-item> -->

            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>capture</b>
                {{ info.deposit_charge.capture }}
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>reversible</b>
                {{ info.deposit_charge.reversible }}
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>expired</b>
                {{ info.deposit_charge.expired }}
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="info.rental_charge">
          <h4>ค่าเช่า</h4>

          <b-list-group flush>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>Token</b>
                {{ info.rental_charge.id }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>ค่าเช่า</b>
                {{ (info.rental_charge.amount / 100) | number }} บาท
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>ชื่อหน้าบัตร</b>
                {{ info.rental_charge.card.name }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>วันหมดอายุบัตร</b>
                {{ info.rental_charge.card.expiration_month }} /
                {{ info.rental_charge.card.expiration_year }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>เลขท้ายบัตร</b>
                {{ info.rental_charge.card.last_digits }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>security_code_check</b>
                {{ info.rental_charge.card.security_code_check }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>created_at</b>
                {{ info.rental_charge.created_at | datetime }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>สถานะ</b>
                {{ info.rental_charge.status }}
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>status</b>
                {{ info.rental_charge.status }}
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>capture</b>
                {{ info.rental_charge.capture }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b></b>
                <div>
                  <b-badge
                    :variant="rentalSuccess(info.rental_charge).variant"
                    v-if="rentalSuccess(info.rental_charge).show"
                  >
                    {{ rentalSuccess(info.rental_charge).message }}
                  </b-badge>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BAlert,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BBadge,
} from "bootstrap-vue";
import moment from "moment";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BAlert,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BBadge,
  },
  props: ["info", "loading"],
  data() {
    return {};
  },
  created() {
    // this.check()
  },
  methods: {
    rentalSuccess(x) {
      if (x.status === "successful" && x.capture === true) {
        return {
          show: true,
          message: "ชำระค่าเช่าสำเร็จ",
          variant: "success",
        };
      } else {
        return {
          show: false,
          message: "ยังไม่ได้ชำระ",
          variant: "danger",
        };
      }
    },
    depositSuccess(x) {
      if (
        x.status === "pending" &&
        x.capture === false &&
        x.reversible === true &&
        x.expired === false
      ) {
        return {
          show: true,
          message: "กันวงเงินสำเร็จ",
          variant: "success",
        };
      } else if (x.expired === true) {
        return {
          show: true,
          message: "วงเงินหมดอายุ",
          variant: "danger",
        };
      } else {
        return {
          show: true,
          message: "คืนวงเงินแล้ว",
          variant: "success",
        };
      }
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    day: function (date) {
      return moment(date).format("D/M");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
    datetime: function (date) {
      return moment(date).format("D/M/Y - HH:mm");
    },
  },
};
</script>
