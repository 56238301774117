<template>
  <div>
    <b-modal
      id="modal-view-blacklist"
      ref="modal-view-blacklist"
      title="Black List"
      size="lg"
      hide-footer
      class="mb-5"
      @shown="loadData"
    >
      <div v-if="data">
        <h3>ข้อมูล Blacklist</h3>
        <div>{{ data.first_name }} {{ data.last_name }}</div>
        <div v-if="data.phone">{{ data.phone }}</div>
        <div v-if="data.email">{{ data.email }}</div>
        <div v-if="data.detail">Detail: {{ data.detail }}</div>
        <div>{{ blacklist_id }}</div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { BModal } from "bootstrap-vue";
export default {
  props: ["blacklist_id"],
  components: {
    BModal,
  },
  data() {
    return {
      data: null,
    };
  },
  methods: {
    loadData() {
      this.$http({
        method: "GET",
        url: `/member/blacklist/${this.blacklist_id}`,
      }).then((x) => {
        // this.form = {};
        this.data = x.data.data;
      });
    },
  },
};
</script>
