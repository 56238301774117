<template>
  <div>
    <b-card>
      <h4>Order ปัจจุบัน</h4>
      <b-overlay
        :show="loading"
        rounded="sm"
        class="dark-layout"
        variant="dark"
      >
        <filter-table :filter_data="order_now" />
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import FilterTable from "../component/FilterTable.vue";
import { BCard, BOverlay } from "bootstrap-vue";
export default {
  components: {
    BCard,
    FilterTable,
    BOverlay,
  },
  data() {
    return {
      form: {},
      order_now: [],
      loading: false,
      order_id: this.$route.params.id,
    };
  },
  created() {
    this.OrderNow();
  },
  props: ["member_id"],
  watch: {
    member_id: {
      deep: true,
      handler(newInfo) {
        this.OrderNow();
      },
    },
  },
  methods: {
    OrderNow() {
      if (this.member_id) {
        this.loading = true;
        const form = {
          side: "order_now",
          member_id: this.member_id,
        };
        form.selected_page = 1;
        form.per_page = 10;
        console.log("form", form);
        this.$http({
          method: "POST",
          url: `/order/list/filter`,
          data: form,
        }).then((x) => {
          const temp_res = x.data.data;
          this.loading = false;
          this.order_now = temp_res.filter(
            (item) => item._id !== this.$route.params.id
          );
        });
      }
    },
  },
};
</script>
