<template>
  <b-modal
    id="modal-add-payment"
    title="แจ้งชำระเงิน"
    ref="modal-add-payment"
    no-close-on-backdrop
    hide-footer
    @shown="loadStore"
  >
    <!-- <h5>รายละเอียดเงิน</h5>
    <p>
      เงินเข้ารวม: {{transaction_info.sum_in | number}}<br>
      เงินออกรวม: {{transaction_info.sum_out | number}}<br>
      ยังไม่ได้ตัดยอด: {{transaction_info.left | number}}<br>
    </p> -->
    <validation-observer ref="addPayment">
      <p>รูปแบบการชำระเงิน</p>
      <b-form-radio-group
        v-model="form.payment_method"
        :options="payment_method_options"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
      >
      </b-form-radio-group>
      <b-form>
        <b-row>
          <b-col xl="4">
            <b-form-checkbox v-model="form.checkbox.rental" class="pb-1">
              ค่าเช่า
            </b-form-checkbox>
          </b-col>
          <b-col xl="8">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="rental"
                :rules="{ required: form.checkbox.rental ? true : false }"
              >
                <b-form-input
                  v-model="form.amount.rental"
                  placeholder="ค่าเช่า"
                  :disabled="!form.checkbox.rental"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="4">
            <b-form-checkbox v-model="form.checkbox.deposit" class="pb-1">
              ค่าประกัน
            </b-form-checkbox>
          </b-col>
          <b-col xl="8">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="deposit"
                :rules="{ required: form.checkbox.deposit ? true : false }"
              >
                <b-form-input
                  v-model="form.amount.deposit"
                  placeholder="ค่าประกัน"
                  :disabled="!form.checkbox.deposit"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="4">
            <b-form-checkbox v-model="form.checkbox.shipping" class="pb-1">
              ค่าส่ง
            </b-form-checkbox>
          </b-col>
          <b-col xl="8">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="shipping"
                :rules="{ required: form.checkbox.shipping ? true : false }"
              >
                <b-form-input
                  v-model="form.amount.shipping"
                  placeholder="ค่าส่ง"
                  :disabled="!form.checkbox.shipping"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="4">
            <b-form-checkbox v-model="form.checkbox.damage" class="pb-1">
              ค่าอุปกรณ์เสียหาย
            </b-form-checkbox>
          </b-col>
          <b-col xl="8">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="damage"
                :rules="{ required: form.checkbox.damage ? true : false }"
              >
                <b-form-input
                  v-model="form.amount.damage"
                  placeholder="ค่าอุปกรณ์เสียหาย"
                  :disabled="!form.checkbox.damage"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="4">
            <b-form-checkbox v-model="form.checkbox.sale" class="pb-1">
              ขาย
            </b-form-checkbox>
          </b-col>
          <b-col xl="8">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="sale"
                :rules="{ required: form.checkbox.sale ? true : false }"
              >
                <b-form-input
                  v-model="form.amount.sale"
                  placeholder="ขายอุปกรณ์"
                  :disabled="!form.checkbox.sale"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              Note
              <b-form-input v-model="form.note" placeholder="Note" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          label="บัญชี"
          label-for="vue-select"
          v-if="form.payment_method == 'transfer'"
        >
          <!-- <v-select
            v-model="form.account"
            label="text"
            :options="account_list_options"
            @input="(z)=>{this.form.account_id = z._id}"
          /> -->
          <b-form-select
            v-model="form.account"
            :options="account_list_options"
          />
        </b-form-group>
        <b-form-group
          label="บัญชี"
          label-for="vue-select"
          v-if="form.payment_method == 'transfer999'"
        >
          <!-- <v-select
            v-model="form.account"
            label="text"
            :options="account_list999_options"
            @input="(z)=>{this.form.account_id = z._id}"
          /> -->
          <b-form-select
            v-model="form.account"
            :options="account_list999_options"
          />
        </b-form-group>
      </b-form>
    </validation-observer>
    <hr />
    <div class="text-right justify">
      <b-button variant="relief-secondary" class="mr-1" @click="cancel">
        Cancel
      </b-button>
      <b-button
        v-if="!loading"
        variant="relief-success"
        @click="validationForm"
      >
        แจ้งชำระเงิน
      </b-button>
      <b-button variant="relief-success" disabled class="mr-1" v-if="loading">
        <b-spinner small />
        Loading...
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BFormSelect,
  BSpinner,
  BRow,
  BCol,
  BCard,
  BCardText,
  BSidebar,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  VBModal,
  BFormCheckbox,
  BButton,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
  components: {
    BFormSelect,
    BCard,
    BCardText,
    BSidebar,
    BModal,
    BFormGroup,
    BForm,
    VBModal,
    BFormInput,
    vSelect,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: ["info"],
  data() {
    return {
      payment_type: [
        {
          th_name: "ค่าเช่า",
        },
      ],
      account_list_options: [],
      account_list999_options: [],
      form: {
        init: {},
        note: null,
        checkbox: {
          damage: false,
          shipping: false,
          deposit: false,
          rental: false,
          sale: false,
        },
        amount: {},
        payment_method: "transfer",
        account: {},
        date: new Date(),
        start_date: null,
        end_date: null,
      },
      loading: false,
      required,
      payment_method_options: [
        {
          name: "000",
          item: "transfer",
        },
        {
          name: "999",
          item: "transfer999",
        },
      ],
      transaction_info: {},
    };
  },
  created() {
    this.loadBankAccount();
  },
  methods: {
    loadStore() {
      if (localStorage.getItem("addpayment")) {
        const temp = JSON.parse(localStorage.getItem("addpayment"));
        this.form.payment_method = temp.payment_method;
        this.form.account = temp.bookbanks._id;
        this.transaction_info = temp.info;
      }
    },
    loadBankAccount() {
      this.$http({
        method: "GET",
        url: `/bookbank/list/`,
      })
        .then((x) => {
          const data = x.data.data;
          this.account_list_options = data.bookbank;
          this.account_list999_options = data.bookbank999;
        })
        .catch((error) => {
          error.response.data;
          console.log(error);
        });
    },
    cancel() {
      this.clearForm();
      this.$refs["modal-add-payment"].hide();
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "การชำระเงิน",
          icon,
          text: "เพิ่มรายการชำระเงินเข้าระบบแล้ว",
          variant,
        },
      });
    },
    clearForm() {
      this.form = {
        checkbox: {
          damage: false,
          shipping: false,
          deposit: false,
          rental: false,
          sale: false,
        },
        amount: {},
        payment_method: "cash",
      };
    },
    validationForm() {
      let data = [];
      this.$refs.addPayment.validate().then(async (success) => {
        let input = this.form;
        for (var key in input.checkbox) {
          if (input.checkbox[key] === true) {
            data.push({
              order_id: this.$route.params.id,
              payment_type: key,
              amount: input.amount[key],
              payment_method: input.payment_method,
              account_id: input.account,
              member_id: this.info.member_id,
              date: new Date(),
              start_date: this.info.start_date,
              end_date: this.info.end_date,
              main: false,
              side: "deposit",
              note: input.note,
            });
          }
        }
        await this.$http({
          method: "POST",
          url: `/payment`,
          data: data,
        })
          .then((x) => {
            this.$root.$emit("order-reload");
            this.$refs["modal-add-payment"].hide();
            this.showToast("success", "CheckIcon");
            this.clearForm();
          })
          .catch((error) => {
            console.log("add-payment-error", error);
          });
      });
    },
  },
  filters: {
    account_no: function (phone) {
      return phone.replace(/(\d{3})(\d{1})(\d{5})(\d+)/, "$1-$2-$3-$4");
    },
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
