<template>
  <!-- <div class="demo-inline-spacing">
        <b-form-radio-group v-model="form.is_register">
          <b-form-radio name="some-radio"
              :value="true"
          >
            สมัครสมาชิกแล้ว
          </b-form-radio>
          <b-form-radio name="some-radio"
              :value="false"
          >
            เอาผู้เช่าออก
          </b-form-radio>
        </b-form-radio-group>
      </div> -->
  <!-- สมัครสมาชิกแล้ว -->
  <div>
    <h5 class="pt-1">ค้นหาจาก เบอร์โทร / email / ชื่อ</h5>
    <vue-autosuggest
      v-model="member_input"
      :suggestions="filteredOptions"
      :get-suggestion-value="getSuggestionValue"
      :input-props="inputProps"
      :limit="10"
      @input="debouncedOnChange"
      @selected="onSelected"
      autocomplete="off"
    >
      <template slot-scope="{ suggestion }">
        <div class="d-flex align-items-center">
          <div class="detail ml-50">
            <b-card-text class="mb-0">
              {{ suggestion.item.member_id }}
              {{ suggestion.item.retro_username }} <br />
              {{ suggestion.item.first_name }} {{ suggestion.item.last_name }}
              <br />
              {{ suggestion.item.phone | phone }} |
              {{ suggestion.item.email }} | {{ suggestion.item.card_id }}
            </b-card-text>
            <!-- <small class="text-muted">
                  {{ suggestion.item.phone }}
                </small>-->
          </div>
        </div>
      </template>
    </vue-autosuggest>
  </div>
</template>

<script>
import {
  BAvatar,
  BCardText,
  BCol,
  BRow,
  BCard,
  BButton,
  BFormRadioGroup,
  BFormRadio,
} from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import _debounce from "lodash.debounce";

export default {
  components: {
    BAvatar,
    BCardText,
    BCol,
    BRow,
    BCard,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    VueAutosuggest,
  },
  props: ["member_id"],
  created() {
    this.loadMember("");
  },
  computed: {
    debouncedOnChange() {
      return _debounce(this.onInputChange, 400);
    },
  },
  data() {
    return {
      member_input: null,
      form: {},
      datasuggest: [],
      filteredOptions: [
        {
          data: [
            { name: "equipment1", data: "lens", time: "sky refuse " },
            { name: "equipment2", data: "lens", time: "your supply " },
          ],
        },
      ],
      /// inputform decoration
      inputProps: {
        id: "autosuggest__input",
        class: "form-control",
        placeholder: "ค้นหา...",
      },
      limit: 30,
      selected: null,
    };
  },
  methods: {
    loadMember(search_text) {
      const request = {
        search_text: search_text,
      };
      this.$http({
        method: "POST",
        url: "/member/list/filtered",
        data: request,
      })
        .then((x) => {
          this.filteredOptions = [
            {
              data: x.data.data,
            },
          ];
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    //// เลือกสมาชิก
    onSelected(option) {
      console.log("onSelected option", option);
      this.$emit("member", option.item);
    },
    async onInputChange(text) {
      this.loadMember(text);
    },
    getSuggestionValue(suggestion) {
      return `${suggestion.item.first_name} ${suggestion.item.last_name}`;
    },
  },
  filters: {
    phone: function (phone) {
      return phone.replace(/(\d{3})(\d{3})(\d+)/, "$1-$2-$3");
    },
    day: function (date) {
      return moment(date).format("D/M");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>
