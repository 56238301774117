<template>
  <b-modal
    id="withdraw-modal"
    title="โอนเงินออก"
    ref="withdraw-modal"
    hide-footer
    @shown="
      () => {
        this.pre_form.amount = this.data.amount;
      }
    "
  >
    <!-- no-close-on-backdrop -->

    <validation-observer ref="addWithdraw">
      <b-form>
        <!-- ยอดเงิน -->
        <b-form-group label="ยอดเงิน">
          <validation-provider
            #default="{ errors }"
            name="Amount"
            rules="required"
          >
            <b-form-input
              v-model="pre_form.amount"
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="วันที่รับเงิน">
          <date-picker
            v-model="pre_form.date"
            mode="datetime"
            is24hr
            :model-config="{
              type: 'string',
              mask: 'iso',
            }"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <b-form-input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="formatDateTimeForDisplay(inputValue)"
                @click="togglePopover()"
                autocomplete="off"
              />
            </template>
          </date-picker>
        </b-form-group>

        <!-- คืนเงิน / ตัดมัดจำ -->

        <div class="demo-inline-spacing mb-2">
          <b-form-radio
            v-model="pre_form.refund_type"
            name="some-radios"
            value="refund"
            >คืนเงิน
          </b-form-radio>
          <b-form-radio
            v-model="pre_form.refund_type"
            name="some-radios"
            value="deduct"
          >
            ตัดมัดจำ
          </b-form-radio>
        </div>

        <div v-if="pre_form.refund_type === 'refund'">
          <div class="d-flex justify-content-between">
            <p>คืนจาก</p>
            <p>{{ return_from }}</p>
          </div>

          <!-- โอนคืน -->
          <div v-if="pre_form.refund_type === 'refund'">
            <!-- เลขที่บชลูกค้า  -->
            <b-form-group label="เลขที่บัญชี">
              <validation-provider
                #default="{ errors }"
                name="account_number"
                rules="required"
              >
                <b-form-input
                  v-model="pre_form.account_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="เฉพาะตัวเลข"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- ชื่อบัญชี  -->
            <b-form-group label="ชื่อบัญชี">
              <validation-provider
                #default="{ errors }"
                name="account_name"
                rules="required"
              >
                <b-form-input
                  v-model="pre_form.account_name"
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- ธนาคาร  -->
            <b-form-group label="ชื่อธนาคาร">
              <validation-provider
                #default="{ errors }"
                name="bank_name"
                rules="required"
              >
                <b-form-input
                  v-model="pre_form.bank_name"
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- ประเภท  -->
            <b-form-group label="ประเภท">
              <validation-provider
                #default="{ errors }"
                name="payment_method"
                rules="required"
              >
                <b-form-input
                  v-model="pre_form.payment_method"
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- {{ data }} -->
          </div>

          <!-- Note -->
          <b-form-group label="Note">
            <b-form-input v-model="pre_form.note" />
          </b-form-group>

          <b-button
            v-if="!loading"
            variant="relief-success"
            @click="validationForm"
          >
            ทำรายการถอนเงิน
          </b-button>
        </div>
        <div v-else>
          <b-form-group label="Note">
            <b-form-input v-model="pre_form.note" autocomplete="off" />
          </b-form-group>
          <b-button v-if="!loading" variant="relief-success" @click="reduct()">
            ทำรายการถอนเงิน
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BSpinner,
  BRow,
  BCol,
  BCard,
  BCardText,
  BSidebar,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  VBModal,
  BFormRadio,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import _ from "lodash";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  components: {
    BCard,
    BCardText,
    BSidebar,
    BModal,
    BFormGroup,
    BForm,
    VBModal,
    BFormInput,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    DatePicker,
  },
  directives: {
    Ripple,
  },
  model: {},
  props: ["data"],
  data() {
    return {
      loading: false,
      return_from: "",
      pre_form: {
        amount: 0,
        payment_type: "",
        refund_type: "refund",
        date: new Date(),
      },
      selected: {},
    };
  },
  watch: {
    data: {
      handler(newData, oldData) {
        this.return_from = newData.bookbanks.display_name;
        this.pre_form.amount = newData.amount;
        this.pre_form.payment_method = newData.bookbanks.type;
      },
      deep: true, // Watch for changes deep within the 'data' object
    },
  },
  created() {},
  methods: {
    formatDateTimeForDisplay(isoDateTime) {
      if (isoDateTime) {
        const parts = isoDateTime.split(" ");
        const dateParts = parts[0].split("/");
        const timePart = parts[1];
        const reformattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${timePart}`;

        const date = new Date(reformattedDate);

        // Format the date and time in 'MM/DD/YYYY HH:mm' format
        const formattedDate = date.toLocaleString("th-TH", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });

        return formattedDate;
      }
    },
    loadData() {
      // this.selected =
    },
    async reduct() {
      let form = this.data;
      form.amount = this.pre_form.amount;
      form.note = this.pre_form.note;
      form.side = "withdraw";
      form.date = this.pre_form.date;

      form = _.omit(form, ["updatedAt", "createdAt", "__v", "_id"]);
      await this.$http({
        method: "POST",
        url: "/payment",
        data: [form],
      }).then(async (y) => {
        this.$refs["withdraw-modal"].hide();
        this.$root.$emit("order-reload");
        // form = this.data;
        // form.amount = this.pre_form.amount;
        // form.side = "deposit";
        // await this.$http({
        //   method: "POST",
        //   url: "/payment",
        //   data: [form],
        // }).then((z) => {

        // });
      });
    },
    validationForm() {
      // this.$refs.addWithdraw.validate().then((success) => {
      //   if (success) {
      //     this.pre_form.refund_status = "pending";
      //     this.pre_form.member_id = this.data.member_id;
      //     this.$http({
      //       method: "POST",
      //       url: "/payment/refund",
      //       data: {
      //         data: this.data,
      //         form: this.pre_form,
      //       },
      //     });
      //   }
      // });

      this.$refs.addWithdraw.validate().then((success) => {
        if (success) {
          let form = this.data;
          form.amount = this.pre_form.amount;
          form.side = "withdraw";
          form.date = this.pre_form.date;

          form = _.omit(form, ["updatedAt", "createdAt", "__v", "_id"]);
          this.$http({
            method: "POST",
            url: "/payment",
            data: [form],
          }).then((x) => {
            console.log("_di", x.data.data);
            const res = x.data.data;
            this.pre_form.refund_status = "pending";
            // this.pre_form.member_id = this.data.member_id;
            // this.pre_form.payment_id = res._id;
            res.forEach((item) => {
              this.$http({
                method: "POST",
                url: "/payment/refund",
                data: {
                  payment_id: item._id,
                  form: {
                    refund_detail: this.pre_form,
                    pending_refund: true,
                  },
                },
              })
                .then((response) => {})
                .catch((error) => {
                  console.error(
                    `Error for item with ID ${item._id}: ${error.message}`
                  );
                });
            });

            this.$refs["withdraw-modal"].hide();
            this.$root.$emit("order-reload");
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
