<template>
  <div>
    <b-row>
      <b-col col-md-8="8">
        <div class="d-flex justify-content-between">
          <h4>เงินเข้า / เงินออก</h4>
          <!-- ทำหักค่ามัดจำ -->
          <div class="mb-1">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-transaction
              variant="relief-success"
              size="sm"
            >
              รับเงิน / คืนเงิน
            </b-button>
          </div>
        </div>
        <b-card no-body>
          <b-table :fields="fields" :items="items" responsive="lg" class="mb-0">
            <template #cell(create_at)="data">
              <span class="text-nowrap">
                {{ data.item.date | date }} -
                {{ data.item.date | time }}
              </span>
              <br />
              <span class="text-nowrap">
                {{ data.item.start_date | date_short }} ||
                {{ data.item.end_date | date_short }}
              </span>
            </template>
            <template #cell(in_amount)="data">
              <span v-if="data.item.side === 'deposit'" class="text-nowrap">{{
                data.item.amount | number
              }}</span>
            </template>
            <template #cell(out_amount)="data">
              <span v-if="data.item.side === 'withdraw'" class="text-nowrap">
                {{ data.item.amount | number }}
              </span>
            </template>

            <template #cell(bookbank)="data">
              <div v-if="data.item.bookbanks">
                <span>{{ data.item.bookbanks.display_name }}</span>
                <div v-if="data.item.side === 'withdraw'">
                  <hr />
                  <div v-if="data.item.refund_detail">
                    เงินออก {{ data.item.refund_detail.account_number }} <br />
                    {{ data.item.refund_detail.account_name }}
                    {{ data.item.refund_detail.bank_name }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(action)="data">
              <b-button
                class="ml-1 mb-1"
                size="sm"
                variant="relief-info"
                @click="copy_info(data.item)"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>

              <b-button
                class="ml-1 mb-1"
                size="sm"
                variant="relief-warning"
                v-b-modal.edit-transaction-modal
                @click="date_edit(data.item)"
              >
                <!-- แก้วันที่ -->
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                class="ml-1 mb-1"
                size="sm"
                variant="relief-success"
                v-b-modal.modal-add-payment
                @click="addPayment(data.item)"
                >ตัดยอด</b-button
              >
              <confirm-delete-button
                @delete="deleteTransaction"
                :data="data.item._id"
                size="sm"
                class="mb-1"
                v-if="show_delete(data.item.createdAt)"
              />
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col col-md-4 cols="3">
        <div class="d-flex justify-content-between">
          <h4>สถานะชำระเงิน</h4>
          <b-button
            size="sm"
            class="ml-1 mb-1"
            variant="relief-info"
            @click="create_tax_invoice"
            >ออกใบกำกับภาษี</b-button
          >
        </div>

        <p>
          เงินเข้ารวม: {{ info.sum_in | number }}<br />
          เงินออกรวม: {{ info.sum_out | number }}<br />
          ยังไม่ได้ตัดยอด: {{ info.left | number }}<br />
        </p>
        <b-alert
          variant="danger"
          :show="info.sum_out > info.sum_in"
          class="mb-1"
        >
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50" />
            เงินออกเกิน !!!!
          </div>
        </b-alert>
        <b-alert variant="danger" :show="info.left != 0" class="mb-0">
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50" />
            ยังไม่ได้ตัดยอด
          </div>
        </b-alert>
        <b-alert
          variant="success"
          :show="info.left == 0 && info.sum_in != 0"
          class="mb-0"
        >
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50" />
            ชำระเงินสำเร็จ
          </div>
        </b-alert>
        <b-alert variant="danger" :show="info.sum_in == 0" class="mb-0">
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50" />
            ยังไม่ได้ชำระเงิน
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <edit-transaction-modal :info="selected"></edit-transaction-modal>
  </div>
</template>
<script>
import {
  BAlert,
  BTable,
  BCard,
  BRow,
  BCol,
  BButton,
  VBModal,
} from "bootstrap-vue";
import ConfirmDeleteButton from "../../components/ConfirmDeleteButton.vue";
import Ripple from "vue-ripple-directive";
import AddPaymentModal from "./AddPaymentModal.vue";
import EditTransactionModal from "./EditTransactionModal.vue";
import { getUserRole } from "@/auth/utils";

import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    ConfirmDeleteButton,
    VBModal,
    AddPaymentModal,
    EditTransactionModal,
    BAlert,
  },
  data() {
    return {
      fields: [
        { key: "create_at", label: "วัน-เวลา" },
        { key: "in_amount", label: "เงินเข้า" },
        { key: "out_amount", label: "เงินออก" },
        { key: "bookbank", label: "ช่องทาง" },
        "action",
      ],
      items: [],
      info: {},
      payment_selected: null,
      selected: {},
      ROLE: "staff",
      now: new Date(),
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  mounted() {
    this.$root.$on("order-reload", async () => {
      await this.loadData();
    });
  },
  created() {
    this.ROLE = getUserRole();
    this.loadData();
  },
  methods: {
    async copy_info(info) {
      const member = await this.$http({
        method: "GET",
        url: `/member/view/${info.member_id}`,
      }).then((z) => {
        return z.data.data;
      });
      const login = member.gen_username || member.retro_username;
      const side = info.side === "deposit" ? "เงินเข้า" : "เงินออก";
      const bank = `${info.bookbanks.t_bank_code} ${info.bookbanks.t_account_name}`;

      const copyText = `${login}
      ${member.first_name} ${member.last_name}
      ${side} - ${bank}
      มัด
      เช่า
      ส่ง
      รวม ${info.amount}`;

      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(copyText)
          .then(() => {
            this.toast_success("Copy แล้ว");
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
          });
      } else {
        console.warn("Clipboard API not supported");
      }
    },

    comma(x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    show_delete(dateToCheck) {
      if (this.ROLE !== "owner") {
        const specificDate = new Date();
        const date = new Date(dateToCheck);
        return (
          specificDate.getUTCFullYear() === date.getUTCFullYear() &&
          specificDate.getUTCMonth() === date.getUTCMonth() &&
          specificDate.getUTCDate() === date.getUTCDate()
        );
      } else {
        return true;
      }
    },
    date_edit(row) {
      this.selected = row;
    },
    create_tax_invoice() {
      window.open("/tax-invoice/create/" + this.$route.params.id, "_blank");
    },
    loadData() {
      this.$http({
        method: "POST",
        url: `/payment/order/`,
        data: {
          order_id: this.$route.params.id,
          main: true,
        },
      }).then((x) => {
        const data = x.data.data;
        this.items = data.table;
        this.info = data.info;
      });
    },
    deleteTransaction(x) {
      if (x.confirm) {
        this.$http({
          method: "DELETE",
          url: `/payment/${x.data}`,
        }).then((x) => {
          this.$root.$emit("order-reload");
        });
      }
    },
    addPayment(x) {
      Object.assign(x, { info: this.info });
      console.log(x);
      localStorage.setItem("addpayment", JSON.stringify(x));
    },
  },
  filters: {
    datetime: function (date) {
      return moment(date).format("D/M/Y - HH:mm");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
    date: function (date) {
      if (date) {
        return moment(date).format("D/M/Y");
      }
    },
    date_short: function (date) {
      if (date) {
        return moment(date).format("D-MM");
      }
    },
    account_no: function (phone) {
      return phone.replace(/(\d{3})(\d{1})(\d{5})(\d+)/, "$1-$2-$3-$4");
    },
    number: function (x) {
      if (x != null) {
        // First, round the number to two decimal places
        var rounded = parseFloat(x).toFixed(2);

        // Then, convert it to a string and format it with commas
        return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    string_limit: (x) => {
      console.log(x);
      return x.toString().substring(0, 12) + "..";
    },
  },
};
</script>
