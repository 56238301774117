<template>
  <div>
    <vue-autosuggest
      ref="autocomplete"
      v-model="query"
      :suggestions="suggestions"
      :input-props="inputProps"
      :render-suggestion="renderSuggestion"
      :get-suggestion-value="getSuggestionValue"
      @input="fetchResults"
      @selected="selectHandler"
    />
  </div>
</template>

<script>
import { BImg, BAvatar, BBadge, BButton } from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";

export default {
  props: ["title"],
  components: {
    VueAutosuggest,
    BImg,
    BAvatar,
    BBadge,
    BButton,
  },
  data() {
    return {
      query: null,
      suggestions: [],
      inputProps: {
        id: "autosuggest__input_ajax",
        placeholder: "ชื่ออุปกรณ์",
        class: "form-control",
        name: "ajax",
        autocomplete: "off",
      },
      timeout: null,
      debounceMilliseconds: 250,
    };
  },
  methods: {
    clear() {
      // this.autocomplete.query = null
      this.query = null;
      this.suggestions = [];
    },
    selectHandler(x) {
      this.$emit("searchSelected", x.item);
    },
    getSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return name === "hotels" ? item.title : item.name;
    },
    renderSuggestion(suggestion) {
      if (suggestion.name === "equipment") {
        const item = suggestion.item;
        if (item.main_image) {
          return (
            <div class="d-flex">
              <div>
                <b-img
                  width="80px"
                  src={item.main_image}
                  size="lg"
                  square
                ></b-img>
              </div>
              <div class="ml-2">
                <h5>{item.name}</h5>
                <span>ค่าเช่า {item.rental_price} บาท/วัน</span>
              </div>
            </div>
          );
        } else {
          return (
            <div class="d-flex">
              <div>
                <b-img
                  width="80px"
                  src="https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
                  size="lg"
                  square
                ></b-img>
              </div>
              <div class="ml-2">
                <h5>{item.name}</h5>
                <span>ค่าเช่า {item.rental_price} บาท/วัน</span>
              </div>
            </div>
          );
        }
      }
      return suggestion.item.name;
    },
    fetchResults() {
      const { query } = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (query.length > 0) {
          const productPromise = await this.$http({
            method: "POST",
            url: `/product/search`,
            data: { keyword: query },
          });

          Promise.all([productPromise]).then((values) => {
            const result = values[0].data.data;
            this.suggestions = [];
            result.length &&
              this.suggestions.push({ name: "equipment", data: result });
          });
        }
      }, this.debounceMilliseconds);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>

<style lang="scss" scoped>
pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}
</style>
