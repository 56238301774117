<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>สภาพอุปกรณ์</h4>
      <div>
        <b-button
          @click="openAddImage(info.order_id)"
          size="sm"
          class="mlr-1 mb-1"
          variant="relief-success"
          >เพิ่มรูปอุปกรณ์</b-button
        >
      </div>
    </div>
    <b-card>
      <div v-for="x in ttt" :key="x._id" class="mb-2">
        <h4>{{ x.product }} | Serial: {{ x.serial }} | No. {{ x.number }}</h4>
        <b-row>
          <b-col xs="3" md="3" v-for="y in x.img_url_list" :key="y._id">
            <a :href="y" target="_blank">
              <b-img-lazy :src="y" fluid />
            </a>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BButton, BCard, BCol, BRow, BImg, BImgLazy } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BImg,
    BImgLazy,

    BButton,
  },
  data() {
    return {
      ttt: {},
    };
  },
  props: ["info"],
  created() {
    this.load_data();
  },
  methods: {
    async load_data() {
      await this.$http({
        method: "post",
        url: `/order/stock-image/list`,
        data: {
          order_id: this.$route.params.id,
        },
      })
        .then((x) => {
          this.ttt = x.data.data;
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    openImage(url) {
      window.open(url, "_blank");
    },
    openAddImage(order_id) {
      const url = `/stock/image/?order_id=${order_id}`;
      window.open(url, "_blank");
    },
  },
};
</script>
