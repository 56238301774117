<template>
  <b-card>
    <div v-for="(data, index) in info" :key="index" class="mt-1">
      <b-row class="d-flex align-items-center">
        <b-col>
          <b-img
            @click="open_modal(data.img_url)"
            height="150"
            rounded
            :src="data.img_url"
            v-b-modal.modal-lg
          />
        </b-col>
        <b-col>
          <!-- <h6> -->
          <h5>{{ data.create_at | date }}</h5>
          <!-- </h6> -->
        </b-col>
      </b-row>
    </div>
    <b-modal
      id="modal-lg"
      size="md"
      scrollable
      cancel-title="Close"
      hide-footer
    >
      <div class="text-center">
        <b-img
          :height="windowHeight"
          rounded
          :src="selected_img"
          v-b-modal.modal-lg
        />
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import { BRow, BCol, BImg, BCard, BAvatar } from "bootstrap-vue";
import moment from "moment";

export default {
  data() {
    return {
      selected_img: null,
      windowHeight: window.innerHeight - 150,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BImg,
  },
  props: {
    info: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    date: function (date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
  methods: {
    open_modal(x) {
      this.selected_img = x;
    },
    handleResize() {
      this.windowHeight = window.innerHeight - 100;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
};
</script>
