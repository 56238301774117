<template>
  <div>
    <vue-good-table
      :isLoading="is_loading"
      :columns="columns"
      :rows="filter_data"
      :rtl="false"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Order -->
        <div v-if="props.column.field === 'order_id'" class="text-nowrap">
          <span v-if="props.row.is_delete">
            <del>order_id: {{ props.row.order_id }} </del>
          </span>
          <span v-else> order_id: {{ props.row.order_id }} </span>
          <!-- <span
            >retro_order_id:
            <a
              target="_blank"
              :href="
                'https://www.lenslineup.com/system/public/admin-lease/view/' +
                props.row.retro_order_id
              "
              >{{ props.row.retro_order_id }}</a
            ></span
          > -->
          <br />
          <!-- <span>_id: {{ props.row._id }}</span> <br /> -->

          <div v-if="props.row.is_delete">
            <b-badge variant="danger" size="lg" class="text-white"
              >-- ถูกลบแล้ว !!!! -- </b-badge
            ><br />
          </div>

          <div v-if="props.row.is_extend">
            <b-badge variant="dark" class="text-white">เช่าต่อ </b-badge><br />
          </div>

          <b-badge
            :variant="props.row.order_status_text.variant"
            class="text-white small-badge"
            >{{ props.row.order_status_text.text }} </b-badge
          ><br />
          <div>
            <b-badge
              variant="success"
              class="text-white small-badge mr-1"
              v-if="props.row.lock_status === true"
            >
              <feather-icon icon="LockIcon" />
            </b-badge>
            <b-badge
              variant="danger"
              class="text-white small-badge mr-1"
              v-if="props.row.lock_status === false"
            >
              <feather-icon icon="UnlockIcon" />
            </b-badge>
            <b-badge
              class="text-white small-badge"
              :variant="props.row.pickup_status_text.variant"
              >{{ props.row.pickup_status_text.text }}
            </b-badge>
          </div>
          <b-badge
            class="text-white small-badge"
            variant="success"
            v-if="props.row.is_paid"
          >
            ชำระเงินแล้ว
          </b-badge>
          <b-badge v-else class="text-white small-badge" variant="danger"
            >ยังไม่ได้ชำระเงิน
          </b-badge>
          <br />

          <div class="text-white small-badge">{{ props.row.time_ago }}</div>

          <br />
          <h5>โอนค่าเช่าล่วงหน้า</h5>
          <b-form-checkbox
            class="custom-control-success"
            name="check-button"
            switch
            v-model="props.row.require_advance_transfer"
            @change="
              update_advance_status(
                props.row._id,
                props.row.require_advance_transfer,
                props.row.total_net_price
              )
            "
          />
        </div>

        <!-- Column: ข้อมูลลูกค้า -->
        <div v-if="props.column.field === 'member'">
          <div v-if="props.row.member">
            <h5 v-if="props.row.member.retro_username">
              ( {{ props.row.member.retro_username }} )
            </h5>
            <h5 v-if="props.row.member.gen_username">
              ( {{ props.row.member.gen_username }} )
            </h5>

            <h5 class="text-nowrap">
              <feather-icon icon="UserIcon" size="12" /> &nbsp;<u
                >{{ props.row.member.first_name }}
                {{ props.row.member.last_name }}</u
              >
            </h5>
            <!-- </div> -->
            <h5 class="text-nowrap">
              <feather-icon icon="PhoneIcon" size="12" /> &nbsp;{{
                props.row.member.phone | phone
              }}
            </h5>
            <h5 class="text-nowrap">
              <feather-icon icon="CreditCardIcon" size="12" /> &nbsp;{{
                props.row.member.card_id
              }}
            </h5>
            <h5 class="text-nowrap">
              <feather-icon icon="CreditCardIcon" size="12" /> &nbsp;{{
                props.row.member.email
              }}
            </h5>

            <h5 style="color: #ff5d03">
              {{ props.row.member.note_admin }}
            </h5>
          </div>
          <div v-else>
            <span>ลูกค้ายังไม่ได้ทำรายการ</span>
          </div>
        </div>

        <!-- Column: ข้อมูลการเช่า -->
        <span v-else-if="props.column.field === 'rental_info'">
          รับ {{ props.row.start_date | day }} -
          <span v-if="props.row.pickup_method == 'self'">
            <!-- {{ props.row.start_date | time }} -->
            <b-badge
              class="text-white"
              variant="danger"
              v-if="time_debug(props.row.start_date) === 'เวลาผิด'"
            >
              เวลาผิด
            </b-badge>
            <span v-else>{{ time_debug(props.row.start_date) }}</span>
          </span>
          <span v-else-if="props.row.pickup_method == 'messenger'">
            {{ messenger_round_text(props.row.start_date) }}
          </span>
          <br />

          คืน {{ props.row.end_date | day }} -
          <b-badge
            class="text-white"
            variant="danger"
            v-if="time_debug(props.row.end_date) === 'เวลาผิด'"
          >
            เวลาผิด
          </b-badge>
          <span v-else>{{ time_debug(props.row.end_date) }}</span>
          <br />

          <b-badge
            :variant="props.row.pickup_method_text.variant"
            class="text-white"
            >{{ props.row.pickup_method_text.text }}</b-badge
          ><br />
        </span>

        <!-- Column: อุปกรณ์ที่เช่า -->
        <span v-if="props.column.field === 'booking'">
          <div v-for="x in props.row.booking" :key="x._id">
            <del v-if="x.is_delete">{{ x.product.name }} <br /></del>
            <span v-else>- {{ x.product.name }} <br /></span>
          </div>
          <br />
          <span>{{ props.row.booking.length }} รายการ</span>
          <span v-if="props.row.deposit_price">
            - วางประกัน: {{ props.row.deposit_price }} บาท
          </span>
          <div v-else>
            <b-badge class="text-white" variant="danger"
              >ยังไม่มียอดวางประกัน</b-badge
            >
          </div>
          <br />

          <b-badge
            class="text-white"
            variant="danger"
            v-if="props.row.require_advance_transfer === true"
          >
            <div>โอนค่าเช่าล่วงหน้า</div>
            <div v-if="props.row.advance_transfer_price > 0">
              {{ props.row.advance_transfer_price | number }} บาท
            </div>
          </b-badge>
          <h5 class="mt-1" style="color: #ff5d03">
            {{ props.row.note_admin }} <br />
            {{ props.row.note_deposit }}
          </h5>
        </span>

        <!-- Column: ค่่าเช่า -->
        <span v-else-if="props.column.field === 'rental_price'">
          {{ props.row.total_net_price }}฿
        </span>

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-button
              variant="relief-success"
              class="btn-icon"
              @click="open_new(props.row._id)"
            >
              <feather-icon icon="SearchIcon" /> Detail
            </b-button>
          </span>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import store from "@/store/index";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";

import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    VueGoodTable,
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
  },
  props: {
    filter_data: {
      type: Array, // Set the prop type to Array
      default: () => [], // Set the default value as an empty array
    },
    is_loading: {
      type: Boolean, // Set the prop type to Boolean
      default: false, // Set the default value to false
    },
  },
  data() {
    return {
      // filter_data2:this.filter_data,
      require_advance_transfer: {},
      pageLength: 100,
      dir: false,
      columns: [
        {
          label: "Order ID",
          field: "order_id",
        },
        {
          label: "อุปกรณ์ที่เช่า",
          field: "booking",
          width: "400px",
        },
        {
          label: "ข้อมูลลูกค้า",
          field: "member",
        },
        {
          label: "ข้อมูลการเช่า",
          field: "rental_info",
        },
        {
          label: "ค่าเช่า",
          field: "rental_price",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: this.okok,
      searchTerm: "",
    };
  },
  created() {},
  methods: {
    time_debug(time) {
      const short_time = moment(time).format("HH:mm");
      const allow_time = ["11:30", "14:00", "17:00", "13:00", "16:00", "19:00"];
      if (allow_time.includes(short_time)) {
        return short_time;
      } else {
        return "เวลาผิด";
      }
    },
    handlePageChange(x) {
      console.log("clickpage", x);
    },
    messenger_round_text(x) {
      const time = moment(x).format("HH:mm");
      if (time == "11:30") {
        const yourDate = moment(x); // Example date
        if (yourDate.day() === 0) {
          return "วันอาทิตย์ 12:00-13:00";
        }
        return "รอบที่ 1 | 13:00-16:00";
      } else if (time == "14:00") {
        return "รอบที่ 2 | 16:00-18:00";
      } else {
        return `xx-${time}`;
      }
    },
    open_new(id) {
      window.open("/order/view/" + id, "_blank");
    },
    cal30(rental_price) {
      const calculatedValue = Math.floor((rental_price * 0.3) / 10) * 10;
      return calculatedValue;
      //
    },
    update_advance_status(order_id, value, rental_price) {
      let temp_advance_transfer_price = 0;
      if (value === true) {
        temp_advance_transfer_price = this.cal30(rental_price);
        //   this.filter_data = this.filter_data.map((order) => {
        //     if (order._id === order_id) {
        //       order.advance_transfer_price = temp_advance_transfer_price;
        //     }
        //     return order;
        //   });
      } else {
        temp_advance_transfer_price = 0;
      }
      this.$http({
        method: "PUT",
        url: `/order/` + order_id,
        data: {
          require_advance_transfer: value,
          advance_transfer_price: temp_advance_transfer_price,
        },
      })
        .then((x) => {
          this.toast(
            "เปลี่ยนสถานะสำเร็จ",
            "Order:" +
              x.data.data.order_id +
              " เปลี่ยนสถานะการชำระเงินล่วงหน้าแล้ว",
            "success"
          );
        })
        .catch((error) => {
          console.log(error.response.data.error);
          this.toast(
            "ผิดพลาด",
            "มีข้อผิดพลาด " + error.response.data.error,
            "danger"
          );
        });
    },
  },
  computed: {
    okok() {
      return this.filter_data;
    },
  },
  filters: {
    phone: function (phone) {
      return phone.replace(/(\d{3})(\d{3})(\d+)/, "$1-$2-$3");
    },
    day: function (date) {
      return moment(date).format("D/M");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
    number(x) {
      if (x != null) {
        x = parseFloat(x);
        // Check if the number is an integer
        if (Number.isInteger(x)) {
          // If it's an integer, return it without any decimal places
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          // If it's not an integer, format it to two decimal places
          return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      } else {
        return x;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.badge {
  margin-top: 0.5rem;
}
.small-badge {
  font-size: 12px;
}
</style>
