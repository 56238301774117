<template>
    <div>
        <b-card class="mt-2" no-body>
            <!-- <div class="demo-inline-spacing"> -->
                <b-row> 
                    <b-col xs="6">
                        <div v-for="x in status_button_list" :key="x.text" >
                            <b-button @click="change_order_status(info,x.value)" :variant="x.variant" v-if="x.variant != 'danger'" class="mb-1" block>
                                {{x.text}}
                            </b-button>
                        </div>
                    </b-col>
                    <b-col xs="6">
                        <div v-for="x in status_button_list" :key="x.text" >
                            <b-button @click="change_order_status(info,x.value)" :variant="x.variant" v-if="x.variant == 'danger'" class="mb-1" block>
                                {{x.text}}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                
            <!-- </div> -->
        </b-card>
    </div>
</template>
<script>
import { BRow,BCol,BCard,BBadge,BListGroup, BListGroupItem,BButton } from 'bootstrap-vue'

export default {
    components: {
        BButton,
        BCard,
        BBadge,
        BListGroup,
        BListGroupItem,
        BRow,
        BCol
    },
    data(){
        return{
            status_button_list:[
                {
                    value:'not_receive',
                    text:"ยังไม่ได้รับ",
                    variant:'danger'
                },
                {
                    value:'received',
                    text:"รับแล้ว",
                    variant:'warning'
                },
                {
                    value:'returned',
                    text:"คืนแล้ว",
                    variant:'success'
                },
            ],
            form:{},

        }
    },
    props:['info'],
    methods: {
        async change_order_status(order,new_status){
            this.$emit('loading',{loading:true})
            await this.$http({
                method: 'PUT',
                url: `/order/`+order._id,
                data: {
                    pickup_status:new_status
                },
            }).then(x=>{
                this.$root.$emit('order-reload')
                this.$emit('loading',{loading:false})
                this.$emit('close')

            })
        }
    },
}
</script>