<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>การชำระเงิน</h4>
    </div>
    <b-card no-body>
      <b-row>
        <b-col>
          <b-table
            :fields="fields"
            :items="items"
            class="mb-0"
            :responsive="true"
          >
            <!-- รายละเอียด -->
            <template #cell(payment_type)="data">
              {{ data.item.payment_type }}
            </template>

            <!-- column ยอดเงิน order -->
            <template #cell(order_amount)="data">
              <div>
                {{ comma(data.item.amount) }}
              </div>
            </template>

            <!-- column ยอดเงิน payment -->
            <template #cell(payment_amount)="data">
              <div>
                {{ comma(data.item.paid) }}
              </div>
            </template>

            <!-- คงเหลือ -->
            <template #cell(left)="data">
              <div>
                {{ comma(data.item.left) }}
              </div>
            </template>

            <template #cell(payment_status)="data">
              <div>
                <!-- :variant="data.item.left > 0 ? 'danger' : 'success'" -->
                <b-badge
                  :variant="data.item.status_variant"
                  v-if="data.item.status_text"
                >
                  {{ data.item.status_text }}
                  <!-- <div v-if="data.item.payment_type != 'ยอดรวม'">
                                    {{ data.item.left > 0  ? 'ยังไม่ครบ' : 'ชำระเงินครบแล้ว' }}
                                </div> -->
                </b-badge>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>

    <transaction-modal :info="info" />

    <add-payment-modal :info="info" />
    <!-- <withdraw-modal /> -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BBadge,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AddPaymentModal from "./AddPaymentModal.vue";
import TransactionModal from "./TransactionModal.vue";
// import WithdrawModal from './WithdrawModal.vue'

import axios from "axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    AddPaymentModal,
    VBModal,
    TransactionModal,
    // WithdrawModal
  },
  props: ["info"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  mounted() {
    this.$root.$on("order-reload", async () => {
      await this.load_payment();
    });
  },
  data() {
    return {
      fields: [
        { key: "payment_type", label: "รายละเอียด" },
        { key: "order_amount", label: "ยอดเงิน (Order)" },
        { key: "payment_amount", label: "ยอดเงิน (Payment)" },
        { key: "left", label: "ค้างชำระ" },
        { key: "payment_status", label: "สถานะ" },
      ],
      items: [],
    };
  },
  created() {
    this.load_payment();
  },
  methods: {
    comma(x) {
      if (x != null) {
        let rounded = Number(x).toFixed(2);

        // Remove the decimal part if it's .00
        if (rounded.endsWith(".00")) {
          rounded = rounded.substring(0, rounded.length - 3);
        }
        return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    async load_payment() {
      await this.$http({
        method: "POST",
        url: `/payment/order/summary`,
        data: {
          order_id: this.$route.params.id,
        },
      }).then((x) => {
        this.items = x.data.data;
      });
    },
  },
  filters: {},
};
</script>
