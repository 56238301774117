<template>
  <div>
    <b-modal
      id="modal-edit-item"
      title="เปลี่ยนตัวอุปกรณ์"
      ref="modal-edit-item"
      no-close-on-backdrop
      hide-footer
      @shown="check()"
    >
      อุปกรณ์ที่ว่าง
      <b-list-group>
        <b-alert show class="mt-1" v-if="check_loading">
          <div class="alert-body">
            <h4><b-spinner /> &nbsp;Loading</h4>
          </div>
        </b-alert>

        <div v-if="info.avaliable.length === 0">
          <b-list-group-item> ไม่มีอุปกรณ์ว่าง </b-list-group-item>
        </div>
        <div v-else>
          <b-list-group-item v-for="(x, i) in info.avaliable" :key="i">
            <b-row>
              <b-col>
                {{ x.product.name }} ตัวที่ {{ x.number }} <br />
                Serial:{{ x.serial }}
              </b-col>
              <b-col class="text-right">
                <b-button variant="relief-success" @click="change(x)" size="sm"
                  >เลือกตัวนี้</b-button
                >
              </b-col>
            </b-row>
          </b-list-group-item>
        </div>
      </b-list-group>
      <br />
      OVERRIDE
      <b-list-group>
        <b-alert show class="mt-1" v-if="check_loading">
          <div class="alert-body">
            <h4><b-spinner /> &nbsp;Loading</h4>
          </div>
        </b-alert>

        <div v-if="info.unavaliable.length != 0">
          <b-list-group-item v-for="(x, i) in info.unavaliable" :key="i">
            <b-row>
              <b-col>
                {{ x.product.name }} ตัวที่ {{ x.number }} <br />
                Serial:{{ x.serial }}
              </b-col>
              <b-col class="text-right">
                <b-button variant="relief-danger" @click="change(x)" size="sm"
                  >เลือกตัวนี้</b-button
                >
              </b-col>
            </b-row>
          </b-list-group-item>
        </div>
      </b-list-group>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BSpinner,
  BAlert,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BSpinner,
    BAlert,
  },
  props: ["booking"],
  data() {
    return {
      info: {
        avaliable: [],
        unavaliable: [],
      },
      check_loading: false,
    };
  },
  created() {
    // this.check()
  },
  methods: {
    /// ได้รุ่นมา axios หาตัวที่ว่าง
    check() {
      this.check_loading = true;
      this.$http({
        method: "GET",
        url: `/order/booking/change/` + this.booking._id,
      })
        .then((x) => {
          this.check_loading = false;
          this.info = x.data.data;
        })
        .catch((x) => {
          console.log("error", x);
          this.check_loading = false;
        });
    },
    change(x) {
      this.$http({
        method: "POST",
        url: `/order/booking/change/` + this.booking._id,
        data: {
          product_id: x.product._id,
          product_sub_id: x._id,
        },
      }).then((x) => {
        this.$refs["modal-edit-item"].hide();
        this.toast("เปลี่ยนตัว", "เปลี่ยนตัวอุปกรณ์ เรียบร้อยแล้ว", "success");
        this.$root.$emit("order-reload");
      });
    },
    toast(title, body, variant) {
      this.$bvToast.toast(body, {
        title: `${title}`,
        toaster: "b-toaster-top-right",
        variant: variant,
        solid: true,
        appendToast: false,
      });
    },
  },
};
</script>
