<template>
  <div>
    <b-modal
      id="modal-change-date"
      title="เปลี่ยนวันรับคืน"
      ref="modal-change-date"
      no-close-on-backdrop
      hide-footer
    >
      <b-overlay
        :show="overlay"
        rounded="sm"
        class="dark-layout"
        variant="dark"
      >
        <h5>วันที่เดิม</h5>
        <b-card no-body>
          <b-list-group flush>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>วัน-เวลารับ</b>
                <div>
                  {{ info.start_date | day }} |
                  <b-badge variant="success">{{
                    info.start_date | time
                  }}</b-badge>
                </div>
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex justify-content-between">
                <b>วัน-เวลาคืน</b>
                <div>
                  {{ info.end_date | day }} |
                  <b-badge variant="success">{{
                    info.start_date | time
                  }}</b-badge>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <hr />
        <h5>วันที่ใหม่</h5>
        <date-selector
          :date_time_info="rental_info"
          @dateForm="getDate"
        ></date-selector>
        <b-button variant="success" @click="checkChange"> เช็คคิว </b-button>

        <div v-if="response_section">
          <hr />

          <h5>ค่าเช่า</h5>
          <b-input-group append="฿" class="mb-1">
            <b-form-input v-model="change_obj.info.total_price_normal" />
          </b-input-group>
          <div v-if="change_obj.info.ready_to_book">
            <b-alert variant="success" show>
              <div class="alert-body">
                <span><strong>อุปกรณ์ว่าง</strong> ว่างสามารถลงจองได้</span>
              </div>
            </b-alert>

            <b-button variant="success" @click="postChange">
              เปลี่ยนวันที่
            </b-button>
          </div>
          <!-- <b-button variant="success" @click="clear() "> Clear </b-button> -->
          <div v-if="!change_obj.info.ready_to_book">
            <b-alert variant="danger" show>
              <div class="alert-body">
                <span><strong>อุปกรณ์ไม่ว่าง !!!</strong></span>
              </div>
            </b-alert>
            <b-button variant="danger" @click="postChange">
              เปลี่ยนวันที่
            </b-button>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BBadge,
  BCard,
  BButton,
  BCol,
  BRow,
  BInputGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BAlert,
  BListGroup,
} from "bootstrap-vue";
import ProductSuggest from "./ProductSuggest.vue";
import axios from "axios";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import DateSelector from "./DateSelector.vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  components: {
    BOverlay,
    BButton,
    ProductSuggest,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BAlert,
    DatePicker,
    DateSelector,
    BBadge,
    BCard,
    BListGroup,
    BListGroupItem,
    BCol,
    BRow,
  },
  props: ["info"],
  directives: {
    Ripple,
  },
  data() {
    return {
      overlay: false,
      form: {
        info: {},
        carts: [],
      },
      response_section: false,
      rental_info: Object.assign({}, this.info),
      change_obj: {
        info: {},
        carts: [],
      },
    };
  },
  created() {
    this.rental_info.start_time = moment(this.info.start_date).format(
      "HH:mm:00"
    );
    this.rental_info.end_time = moment(this.info.end_date).format("HH:mm:00");
    this.rental_info.start_date = moment(this.info.start_date).format(
      "YYYY-MM-DD"
    );
    this.rental_info.end_date = moment(this.info.end_date).format("YYYY-MM-DD");
    this.booking = this.$parent.booking;
  },
  methods: {
    getDate(value) {
      /// เลือกวันเวลาแล้วได้ค่ากลับมา
      this.form.info = value;
      this.response_section = false;
    },

    clear() {
      this.response = {
        product: {
          rental_price: null,
          avaliable: null,
        },
        info: {},
      };
    },
    checkChange() {
      this.overlay = true;
      this.form.carts = this.$parent.booking.map((x) => {
        const z = {};
        z.product_id = x.product_id;
        z.booking_id = x._id;
        z.start_date = this.form.info.start_date;
        z.end_date = this.form.info.end_date;
        z.start_date_lock = this.form.info.start_date;
        z.end_date_lock = this.form.info.end_date;
        return z;
      });
      this.$http({
        method: "POST",
        // url: `/queue/change-check`,
        url: "/order/change-date-check",
        data: this.form,
      }).then((x) => {
        const data = x.data.data;
        this.overlay = false;
        this.ready_to_book = data.info.ready_to_book;
        this.response_section = true;
        this.change_obj = data;
      });
    },
    postChange() {
      this.change_obj.info.order_id = this.info.order_id;
      this.change_obj.info.full_order_id = this.info._id;

      this.$http({
        method: "POST",
        url: `/order/change-date-update`,
        data: this.change_obj,
      }).then((x) => {
        this.$refs["modal-change-date"].hide();
        this.toast(
          "เปลี่ยนวันที่เช่า",
          "เปลี่ยนวันท่ีเช่าเรียบร้อยแล้ว",
          "success"
        );
        this.$root.$emit("order-reload");
      });
    },
    toast(title, body, variant) {
      this.$bvToast.toast(body, {
        title: `${title}`,
        toaster: "b-toaster-top-right",
        variant: variant,
        solid: true,
        appendToast: false,
      });
    },
  },
  filters: {
    day: function (date) {
      return moment(date).format("D-M-YYYY");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>
