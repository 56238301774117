<template>
    <div>
         <!-- modal vertical center -->
        
            <b-modal
                id="modal-change-status"
                ref="modal-change-status"
                centered
                title="เปลี่ยนสถานะ"
                hide-footer
            >
            <!-- ปุ่มรอจอง -->
            <b-overlay
                :show="overlay"
                rounded="sm"
                class="dark-layout"
                variant="dark"
            >
                <order-status-switch @loading="loading" @close="close" :info=info ></order-status-switch>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import OrderStatusSwitch from './OrderStatusSwitch.vue' /// สถานะ Order
import { BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default {
    components: {
        OrderStatusSwitch,
        BOverlay
    },
    data() {
        return {
            overlay:false
        }
    },
    directives: {
        Ripple,
    },
    props:['info'],
    methods:{
        close(){
            this.$refs["modal-change-status"].hide()
        },
        loading(x){
            this.overlay = x.loading
        }
        
    }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';


.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>