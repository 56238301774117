<template>
  <div>
    <b-modal
      id="edit-order-modal"
      title="แก้ไขข้อมูล Order"
      ref="edit-order-modal"
      no-close-on-backdrop
      hide-footer
      class="mb-3"
    >
      <b-form-group label="ยอดวางประกัน">
        <b-form-input v-model="edit_form.deposit_price" />
      </b-form-group>

      <b-form-group label="ค่าเช่ารวม">
        <b-form-input v-model="edit_form.rental_price" />
      </b-form-group>

      <b-form-group label="ค่าส่ง">
        <b-form-input v-model="edit_form.shipping_price" />
      </b-form-group>

      <b-form-group label="ส่วนลด">
        <b-form-input v-model="edit_form.discount_price" />
      </b-form-group>
      <b-form-group label="หัก​ ณ ที่จ่าย">
        <b-form-radio-group
          v-model="edit_form.has_withholding_tax_price"
          class="mb-1"
        >
          <b-form-radio :value="true"> มี </b-form-radio>
          <b-form-radio :value="false"> ไม่มี </b-form-radio>
        </b-form-radio-group>
        <div class="row" v-if="edit_form.has_withholding_tax_price">
          <div class="col-8">
            <b-form-input v-model="edit_form.withholding_tax_price" />
          </div>
          <div class="col">
            <b-button
              @click="
                calWithHolding(
                  edit_form.rental_price - edit_form.discount_price
                )
              "
              variant="success"
              >คำนวนยอดหัก</b-button
            >
          </div>
        </div>
      </b-form-group>

      <b-form-group label="วิธีรับอุปกรณ์">
        <b-form-radio-group v-model="edit_form.pickup_method">
          <b-form-radio value="self"> รับเองที่ร้าน </b-form-radio>
          <b-form-radio value="messenger"> ส่ง Messenger </b-form-radio>
          <b-form-radio value="kerry"> ส่งพัสดุ Kerry </b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <b-form-group label="การชำระเงิน">
        <b-form-radio-group v-model="edit_form.require_advance_transfer">
          <b-form-radio :value="false"> ชำระเต็มหน้าร้าน </b-form-radio>
          <b-form-radio :value="true"> โอนล่วงหน้า</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-form-group
        label="ยอดที่ต้องโอนล่วงหน้า"
        v-if="edit_form.require_advance_transfer"
      >
        <b-form-input v-model="edit_form.advance_transfer_price" />
      </b-form-group>

      <b-form-group
        label="ที่อยู่จัดส่ง"
        v-if="edit_form.pickup_method != 'self'"
      >
        <b-form-textarea v-model="edit_form.shipping.formatted_address" />
      </b-form-group>

      <b-form-group label="Note หลังร้าน">
        <b-form-textarea v-model="edit_form.note_admin" />
      </b-form-group>

      <b-form-group label="Note วางประกัน">
        <b-form-textarea v-model="edit_form.note_deposit" />
      </b-form-group>

      <b-form-group label="Note ลูกค้า">
        <b-form-textarea v-model="edit_form.note_user" />
      </b-form-group>

      <b-form-group label="โหมดราคา">
        <b-form-radio-group v-model="edit_form.price_mode">
          <b-form-radio value="auto"> Auto </b-form-radio>
          <b-form-radio value="manual"> Manual </b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <b-button @click="postEdit" variant="success">แก้ไข Order</b-button>

      <!-- <h1>MODAL</h1> -->
      <!-- {{info}}
            ยอดวางประกันรวม
            ค่าเช่ารวม
            ค่าส่ง
            ส่วนลด
            ยอดชำระ NET
            order_note
            pickup_method
            pickup_status -->
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormInput,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BForm,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCol,
    BRow,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
  },
  props: ["info"],
  data() {
    return {};
  },
  computed: {
    edit_form: {
      get() {
        return this.info;
      },
      set(value) {
        this.info = value;
      },
    },
    // edit_form() {
    //   return Object.assign({}, this.info);
    // },
  },
  // watch: {
  //   info: function (newVal) {
  //     this.edit_form = Object.assign({}, newVal);
  //   },
  // },
  created() {},
  methods: {
    postEdit() {
      this.edit_form.order_id = this.edit_form.order_id_text;
      if (!this.edit_form.has_withholding_tax_price) {
        this.edit_form.withholding_tax_price = 0;
      }
      let filtered_edit_form = this.edit_form;
      delete filtered_edit_form["stock_image"];
      this.$http({
        method: "PUT",
        url: "/order/" + this.$route.params.id,
        data: filtered_edit_form,
      })
        .then((x) => {
          this.$refs["edit-order-modal"].hide();
          this.toast("แก้ไข Order", "แก้ไข Order เรียบร้อยแล้ว", "success");
          this.$root.$emit("order-reload");
        })
        .catch((error) => {
          this.$refs["edit-order-modal"].hide();
          this.toast("แก้ไข Order", "การแก้ไขผิดพลาด", "danger");
        });
    },
    toast(title, body, variant) {
      this.$bvToast.toast(body, {
        title: `${title}`,
        toaster: "b-toaster-top-right",
        variant: variant,
        solid: true,
        appendToast: false,
      });
    },
    calWithHolding(price) {
      const temp = ((parseFloat(price) / 1.07) * 0.05).toFixed(2);
      this.edit_form.withholding_tax_price = temp;
    },
  },
};
</script>
