<template>
    <div>
        <h4>ข้อมูลผู้เช่า</h4>   
        <b-card >
            <!-- v-if="userData" -->
            <b-row>
                <!-- User Info: Left col -->
                <b-col lg="12" class="d-flex justify-content-between flex-column">
                    <!-- User Avatar & Action Buttons -->
                    <!-- :text="avatarText(userData.fullName)" -->
                    <div class="d-flex justify-content-start">
                        <!-- รูป -->
                        <b-avatar
                            src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/13-small.d796bffd.png"
                            variant=""
                            size="104px"
                            rounded
                        />
                        <div class="d-flex flex-column ml-1" >
                            <h3 v-if="Object.keys(member).length == 0">ยังไม่มีผู้เช่า</h3>
                            <div class="mb-1">
                                <h4 class="mb-0">
                                    <span>{{member.member_id}}</span> <br>
                                </h4>
                                <span class="card-text">{{ member.email }}</span>
                            </div>
                            <div class="d-flex flex-wrap">
                                <b-button
                                    :to="{ name: 'member-view', params: { id: member._id } }"
                                    variant="primary"
                                >
                                    Edit
                                </b-button>
                            </div>
                        </div>
                    </div>

                    <!-- สถิติการเช่า -->
                    <div class="d-flex align-items-center mt-2">
                    <div class="d-flex align-items-center mr-2">
                        <b-avatar
                        variant="light-primary"
                        rounded
                        >
                        <feather-icon
                            icon="DollarSignIcon"
                            size="18"
                        />
                        </b-avatar>
                        <div class="ml-1">
                        <h5 class="mb-0">
                            23.3k
                        </h5>
                        <small>ยอดเช่าทั้งหมด</small>
                        </div>
                    </div>

                    <div class="d-flex align-items-center">
                        <b-avatar
                        variant="light-success"
                        rounded
                        >
                        <feather-icon
                            icon="TrendingUpIcon"
                            size="18"
                        />
                        </b-avatar>
                        <div class="ml-1">
                        <h5 class="mb-0">
                            1111
                        </h5>
                        <small>จำนวนครั้งที่เช่า</small>
                        </div>
                    </div>
                    </div>
                </b-col>
                <!-- Right Col: Table -->
                <b-col lg="12">
                    <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                        <th class="pb-50">
                        <feather-icon
                            icon="UserIcon"
                            class="mr-75"
                        />
                        <span class="font-weight-bold">ชื่อ-นามสกุล</span>
                        </th>
                        <td class="pb-50">
                        {{ member.full_name }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                        <feather-icon
                            icon="CircleIcon"
                            class="mr-75"
                        />
                        <span class="font-weight-bold">อายุ</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                        {{ member.age }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                        <feather-icon
                            icon="CreditCardIcon"
                            class="mr-75"
                        />
                        <span class="font-weight-bold">เลขที่บัตรประชาชน</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                        {{ member.role }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                        <feather-icon
                            icon="PhoneIcon"
                            class="mr-75"
                        />
                        <span class="font-weight-bold">เบอร์โทรศัพท์</span>
                        </th>
                        <td class="pb-50">
                        {{ member.phone }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                        <feather-icon
                            icon="FlagIcon"
                            class="mr-75"
                        />
                        <span class="font-weight-bold">Note</span>
                        </th>
                        <td>
                        {{ member.note }}
                        </td>
                    </tr>
                    </table>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BCard, BCardText, BCol, BRow, BListGroup, BListGroupItem, BBadge, BButton,BAvatar,BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BCard,
        BCardText,
        BRow,
        BCol,
        BListGroup, 
        BListGroupItem,
        BBadge,
        BButton,
        BAvatar,
        BTable,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            member:{}
        }
    },
    mounted(){
        this.$root.$on('member-reload',async (x) => {
            this.info.member_id = x
            await this.load_data()
        })
    },
    props: ['info'],
    created() {
        this.load_data()
    },
    methods:{
        async load_data(){
            console.log('this.info',this.info)
            await this.$http({
                method: 'GET',
                url: `/member/view/${this.info.member_id}`,
                data: {
                    side:'all'
                },
            }).then(x=>{
                console.log('member data',x.data)
                this.member = x.data
            }).catch(e=>{
                console.log('error',e)
            })

        }
    }
}
</script>

<style>

</style>
