<template>
    <div>
        <b-modal
            id="modal-change-member"
            title="เปลี่ยนผู้เช่า"
            ref="modal-change-member"
            no-close-on-backdrop
            hide-footer
        > 
            <member-selector :member_id="info.member_id" @member="MemberSelected"></member-selector>
            <b-card no-body class="mt-1">
                <b-button @click="ChangeMember" variant="success" :disabled="load_change_member"><b-spinner v-if="load_change_member" small /> เปลี่ยนผู้เช่า</b-button>
            </b-card>
        </b-modal>
    </div>
</template>

<script>

import { BSpinner,BBadge,BCard,BButton,BCol, BRow,BInputGroup,BListGroupItem,BFormGroup,BFormInput,BAlert,BListGroup } from 'bootstrap-vue'
import ProductSuggest from './ProductSuggest.vue'
import Ripple from 'vue-ripple-directive'
import MemberSelector from './MemberSelector.vue'
import moment from 'moment'

export default {
    components: {
        BButton,
        ProductSuggest,
        BInputGroup,
        BFormGroup,
        BFormInput,
        BAlert,
        BBadge,
        BCard,
        BListGroup,
        BListGroupItem,
        BCol, 
        BRow,
        MemberSelector,
        BSpinner

    },
    directives: {
        Ripple,
    },
    props:['info'],
    data() {
        return {
            form:{
                member_id:'',
                order_id:this.info._id
            },
            load_change_member:false
        }
    },
    created(){
        
    },
    methods:{
        delay(time) {
            return new Promise(resolve => setTimeout(resolve, time));
        },
        MemberSelected(x){
            this.form.member_id = x._id
        },
        ChangeMember(){
            if(this.form.member_id!=null){
                this.load_change_member = true
                this.$http({
                    method:'PUT',
                    url:'/order/edit/member',
                    data:this.form
                }).then(async x => {
                    if(x.data.success){
                        this.$root.$emit('order-reload')
                        this.$refs["modal-change-member"].hide()
                        this.load_change_member = false
                        this.form.member_id = null
                    }else{
                    }
                }).catch(e=>{
                    this.load_change_member = false
                    this.form.member_id = null
                    console.log('change member error',e.response.data.error)
                })
            }
        }
    },
    filters: {
        day: function (date) {
            return moment(date).format('D-M-YYYY');
        },
        time: function (date) {
            return moment(date).format('HH:mm');
        }
    }
}
</script>